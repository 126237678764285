"use client";
import React from "react";
import Image from "next/image";
import { useOfferContext } from "context/OfferContext";
import realtorReferalPartners from "config/realtor-referral-partners.json";
import Label from "elements/Input/Label";
import { ReferralRealtorPartnerDetails } from "utils/types";
import { usePathname } from "next/navigation";

const RealtorReferralPartnerBanner = ({
	partner,
}: {
	partner?: ReferralRealtorPartnerDetails;
}) => {
	const { offer } = useOfferContext();
	const pathname = usePathname();

	const realtorReferalPartnerConfig = React.useMemo(() => {
		if (!offer) return;
		let tmpPartner = Object.values(realtorReferalPartners).find(partner =>
			partner.urls.includes(offer)
		);
		if (tmpPartner) {
			return tmpPartner;
		} else {
			if (partner) {
				return partner;
			}
			return null;
		}
	}, [offer]);

	if (!realtorReferalPartnerConfig) return null;

	if (!pathname.includes("/partner/")) return null;

	const {
		name,
		photo,
		agency,
		phone,
	}: { name: string; photo?: string; agency: string; phone: string } =
		realtorReferalPartnerConfig;

	return (
		<div id="realtor-partner-referral-banner">
			<div className="grid">
				<div id="realtor-partner-referral-banner-profile">
					{photo && (
						<div id="realtor-partner-referal-banner-image-container">
							{typeof photo === "string" ? (
								<img src={photo} alt={name} className="profile-photo" />
							) : (
								<Image
									width="100"
									height="100"
									src={photo}
									alt={`${name} Profile Photo`}
									quality={100}
									className="profile-photo"
								/>
							)}
						</div>
					)}
					<div>
						<Label>YOU'VE BEEN REFERRED BY:</Label>
						<p className="bold">{name}</p>
						<p className="sm">{agency}</p>
						<p className="sm">{phone}</p>
					</div>
				</div>
				<div id="realtor-partner-referral-banner-testimonial">
					<Label>WHY SIGN UP FOR OWNWELL?</Label>
					<p className="sm">
						Ownwell works hard to keep your property taxes as low as possible
						every year. Activate your Exemptions on day one and put your Appeals
						on auto-pilot.
					</p>
				</div>
			</div>
		</div>
	);
};

export default RealtorReferralPartnerBanner;
