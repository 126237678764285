import React from "react";
import { useSearchParams } from "next/navigation";
import { useResponsiveContext } from "context/ResponsiveContext";

export const useOnboardingStyles = (): React.CSSProperties => {
	const searchParams = useSearchParams();
	const { isDesktop } = useResponsiveContext();

	const styles = React.useMemo(() => {
		const styles: React.CSSProperties = {};

		if (searchParams.get("onboarding") && isDesktop) {
			styles.pointerEvents = "none";
		}

		return styles;
	}, [searchParams.get("onboarding"), isDesktop]);

	return styles;
};
