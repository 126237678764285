import React from "react";
import { usePathname } from "next/navigation";
import { useEventTracker } from "utils/useEventTracker";
import { useAppealContext } from "context/AppealContext";
import { useAuthContext } from "context/AuthContext";
import { useAutocomplete } from "utils/useAutocomplete";
import { useFocusElementOverBackground } from "utils/useFocusElementOverBackground";

import states from "public/states.json";
import cleanAddress from "utils/cleanAddress";
import toTitleCase from "utils/toTitleCase";
import constants from "utils/constants";

import Search from "elements/Input/Search";
import ButtonV2 from "elements/ButtonV2";
import { SignUpFlowServiceType } from "utils/types";

const cleanAddressForAppeal = (userInput: string) => {
	const address = {
		address: "",
		city: "",
		state: "",
		zipcode: "",
	};
	userInput = userInput.trim().replace(/\s\s+/g, " ").replace(/ *, */g, " "); //replaces extra whitespaces + replaces commas with whitespace

	//Edge Case: We can assume that someone only typed in their first line of their address (ex: 123 Lily). This is for dealing with '123 California'
	if (userInput.split(" ").length <= 2) {
		return "?address=" + encodeURIComponent(userInput);
	}

	let tokens = userInput.split(" ").reverse();
	let tokensCopy = [...tokens];

	for (let i = 0; i < tokens.length; i++) {
		let currentToken = tokens[i];

		if (i === 0 || i === 1) {
			//Zipcode
			if (i === 0 && +currentToken * 0 === 0 && currentToken.length === 5) {
				address.zipcode = tokens[i];
				tokensCopy.splice(tokensCopy.indexOf(currentToken), 1);
			}

			//State
			//if value.length === 2, that means it can be a state in "TX" format. Else if value.length > 2, it can be in "Texas" format
			let stateFound;
			if (i === 0 || (address.zipcode !== "" && i === 1)) {
				if (currentToken.length === 2 && currentToken.toUpperCase() !== "CT") {
					stateFound =
						states[currentToken.toUpperCase() as keyof typeof states];
				} else if (currentToken.length > 2) {
					stateFound =
						Object.keys(states).find(
							key =>
								states[key as keyof typeof states].toUpperCase() ===
								currentToken.toUpperCase()
						) || "";
				}
				if (stateFound) {
					address.state = stateFound;
					tokensCopy.splice(tokensCopy.indexOf(currentToken), 1);
				}
			}
		}
	}
	address.address = tokensCopy.reverse().join(" "); //Address line 1

	return (
		"?address=" +
		encodeURIComponent(address.address) +
		"&zipcode=" +
		encodeURIComponent(address.zipcode) +
		"&state=" +
		encodeURIComponent(address.state)
	);
};

const useSendToAppeal = (query: string) => {
	const { setAddress, startAppealFlow, isEndToEndExemptionsFlow } =
		useAppealContext();
	const trackEvent = useEventTracker();
	return () => {
		let cleanQuery = cleanAddressForAppeal(query);

		if (isEndToEndExemptionsFlow) {
			cleanQuery += cleanQuery.includes("?")
				? "&exemptions=true"
				: "?exemptions=true";

			trackEvent({
				eventName:
					constants.EVENTS.E2E_Exemptions_LP_Address_Entry_DontSeeAddress,
				data: {
					Address: query,
				},
			});
		} else {
			trackEvent({
				eventName:
					constants.EVENTS.Main_Landing_Page_Address_Entry_DontSeeProperty,
				data: {
					Address: query,
				},
			});
		}
		startAppealFlow(`/appeal${cleanQuery}`);
	};
};

export const HeroAdressInput: React.FC<{
	title?: string;
	buttonCopy?: string | JSX.Element | undefined;
	preferredState?: string;
}> = ({ title, buttonCopy, preferredState }) => {
	const { setAddress, startAppealFlow, isEndToEndExemptionsFlow } =
		useAppealContext();

	const { isAuthenticated } = useAuthContext();

	const [query, setQuery] = React.useState("");
	const [showResults, setShowResults] = React.useState(false);
	const [activeIndex, setActiveIndex] = React.useState<number>();

	const { autoCompleteLoading, autoCompleteResults } = useAutocomplete(
		query,
		preferredState
	);

	const trackEvent = useEventTracker();

	const pathname = usePathname();

	const {
		ref: focusRef,
		setFocused,
		focused,
	} = useFocusElementOverBackground<HTMLDivElement>();

	const handleItemSelect = (index: number) => {
		if (!autoCompleteResults) return;

		const {
			situsfullstreetaddress,
			situscity,
			situsstate,
			situszip5,
			fips,
			apn,
			county,
		} = autoCompleteResults[index].params;

		const addressData = {
			address: cleanAddress(situsfullstreetaddress),
			line2: "",
			city: toTitleCase(situscity),
			state: situsstate,
			zipcode: situszip5,
			fips,
			apn,
			county,
		};

		setAddress(addressData);

		if (pathname === "/") {
			trackEvent({
				eventName:
					constants.EVENTS.Main_Landing_Page_Address_Entry_ResultsSuccess,
				data: {
					Address: query,
				},
			});
			startAppealFlow("/appeal/savings");
		} else {
			if (pathname === "/exemptions") {
				trackEvent({
					eventName:
						constants.EVENTS.E2E_Exemptions_LP_Address_Entry_Auto_Complete,
					data: {
						"User Type": isAuthenticated ? "Returning" : "New",
					},
				});
			}

			startAppealFlow("/appeal/savings");
		}
		setFocused(false);
	};

	const handleKeyPress = React.useCallback(
		e => {
			if (!autoCompleteResults.length || !query) return;

			if (e.key === "ArrowUp") {
				e.preventDefault();
				let newIndex;
				if (typeof activeIndex !== "undefined") {
					newIndex =
						activeIndex === 0
							? autoCompleteResults.length - 1
							: activeIndex - 1;
				} else {
					newIndex = autoCompleteResults.length - 1;
				}
				setActiveIndex(newIndex);
			}

			if (e.key === "ArrowDown") {
				e.preventDefault();
				let newIndex;
				if (typeof activeIndex !== "undefined") {
					newIndex =
						activeIndex === autoCompleteResults.length - 1
							? 0
							: activeIndex + 1;
				} else {
					newIndex = 0;
				}
				setActiveIndex(newIndex);
			}

			if (e.key === "Enter") {
				e.preventDefault();
				if (activeIndex !== undefined) {
					handleItemSelect(activeIndex);
				}
			}
		},
		[autoCompleteResults.length, query, activeIndex]
	);

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		setQuery(e.target.value);
	};

	const handleSendToAppeal = useSendToAppeal(query);

	const containerRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (!showResults) setActiveIndex(undefined);
	}, [showResults]);

	React.useEffect(() => {
		if (showResults && query) {
			setActiveIndex(undefined);
		}
	}, [showResults, query]);

	React.useEffect(() => {
		const handler = () => {
			setShowResults(false);
		};

		window.addEventListener("click", handler);

		return () => {
			window.removeEventListener("click", handler);
		};
	}, []);

	React.useEffect(() => {
		if (query.length > 1 && autoCompleteResults?.length) {
			setShowResults(true);
		} else if (!query && !autoCompleteResults?.length) {
			setShowResults(false);
		}
	}, [autoCompleteResults, query]);

	return (
		<div id="landing-hero-address-input">
			{title && <h1 id="landing-hero-title">{title}</h1>}
			<div ref={focusRef} id="landing-hero-address-search-with-results">
				<div
					ref={containerRef}
					id="landing-hero-address-search"
					className={[
						focused ? "focused" : "",
						showResults ? "results" : "",
					].join(" ")}>
					<Search
						onChange={handleInputChange}
						onKeyDown={handleKeyPress}
						placeholder="Enter address"
						autoComplete="off"
						required={false}
						showRightIcon={false}
					/>
					<div className="search-separator" />
					<ButtonV2 id="address-search-input-button">
						Get Savings Estimate
					</ButtonV2>
				</div>
				{showResults && (
					<>
						<div className="search-results-separator-section">
							<div
								className="search-results-separator-line"
								style={{
									width: containerRef.current
										? containerRef.current.clientWidth - 16 // 16px padding
										: 0,
								}}
							/>
						</div>
						<div id="landing-title-autocomplete-results-wrapper">
							<div
								id="landing-title-autocomplete-results-container"
								onMouseLeave={() => setActiveIndex(undefined)}
								onScrollCapture={e => {
									e.preventDefault();
									e.stopPropagation();
								}}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
								}}>
								{autoCompleteResults
									?.slice(0, 3)
									.map(({ label, params }, i) => {
										const showParcel =
											autoCompleteResults[i - 1]?.label === label ||
											autoCompleteResults[i + 1]?.label === label;

										return (
											<div
												key={label + i}
												className={
													"landing-title-autocomplete-item" +
													(i === activeIndex ? "-active" : "")
												}
												onMouseOver={() => setActiveIndex(i)}
												onClick={() => handleItemSelect(i)}>
												<p className="no-translate">{label}</p>
												{showParcel && (
													<label>
														Parcel:{" "}
														<span className="no-translate">{params.apn}</span>
													</label>
												)}
											</div>
										);
									})}
							</div>
							<div
								className="dont-see-address button-small"
								onClick={() => {
									handleSendToAppeal();
									setFocused(false);
								}}>
								Don't see your address? Click here.
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
