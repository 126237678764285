import React from "react";
import { usePropertyContext } from "../context";
import { SurveyBuilder } from "../../../../elements/SurveyBuilder";
import { assign } from "xstate";
import {
	DeepPartial,
	MortgageRates,
	OneZeroOrNull,
	PropertyDetails,
} from "utils/types";
import { useAuthContext } from "context/AuthContext";
import {
	IPropertyEquityContext,
	usePropertyEquityContext,
} from "../PropertyEquityContext";
import client from "utils/client";

import SingleFamilyHomeSVG from "public/survey-builder/single-family-home.svg";
import CondoSVG from "public/survey-builder/condo.svg";
import MultifamilySVG from "public/survey-builder/multifamily.svg";
import ManufacturedSVG from "public/survey-builder/manufactured.svg";
import PrimaryResidenceSVG from "public/survey-builder/primary-residence.svg";
import VacationHomeSVG from "public/survey-builder/vacation-home.svg";
import InvestmentPropertySVG from "public/survey-builder/investment-property.svg";
import MortgageClipboardSVG from "public/mortgage-clipboard.svg";
import CheckInKellyDarkCircleSVG from "public/check-in-kelly-dark-circle.svg";
import RefinanceMonitoringIntroIllustrationSVG from "public/survey-builder/refinance-monitoring-intro-illustration.svg";
import { Checkbox, Label, TextField } from "elements/Input";
import {
	createTooltipLabel,
	EXPENSES_ESCROW_TOOLTIP,
	EXPENSES_INSURANCE_TOOLTIP,
	EXPENSES_PMI_TOOLTIP,
	EXPENSES_PROPERTY_TAX_TOOLTIP,
} from "../Expenses/shared/tooltips";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import QuickLink from "elements/QuickLink";
import ButtonV2 from "elements/ButtonV2";
import { useRouter } from "next/navigation";
import useMortgageRates from "utils/useMortgageRates";
import { generateRefinanceSavingsResults } from "utils/generateRefinanceSavings";
import merge from "lodash.merge";
import { motion } from "framer-motion";
import moment from "moment";
import lottie from "lottie-web/build/player/lottie_light";

export namespace RefinanceMonitoring {
	export interface Fields {
		refinance_goal?:
			| null
			| string
			| "lower-payment"
			| "pay-off-mortgage"
			| "cash-out"
			| "change-arm-to-fixed";
		has_second_mortgage?: OneZeroOrNull;
		estimated_mortgage_balance?: null | number;
		estimated_mortgage_balance_set_at?: string;
		current_employment_status?:
			| null
			| string
			| "employed"
			| "self-employed"
			| "retired"
			| "unemployed";
		credit_score_range?:
			| null
			| string
			| "579 or below"
			| "580-619"
			| "620-639"
			| "640-659"
			| "660-679"
			| "680-699"
			| "700-719"
			| "720-739"
			| "740-759"
			| "760-779"
			| "780+";
		declared_bankruptcy_within_last_3_years?: OneZeroOrNull;
		veteran_or_active_military?: OneZeroOrNull;
		annual_income_range?:
			| null
			| string
			| "less than $50,000"
			| "$50,000-$100,000"
			| "$100,000-$150,000"
			| "$150,000-$200,000"
			| "more than $200,000"
			| "I do not wish to provide my income.";
		in_escrow?: OneZeroOrNull;
		escrow_monthly_total_payment?: null | string;
		escrow_monthly_pmi_payment?: null | string;
		escrow_annual_property_taxes?: null | string;
		escrow_monthly_insurance_payment?: null | string;
		completed_survey?: 1 | 0;
	}

	const refinance_goal_value_to_label = (
		refinance_goal: Exclude<Required<Fields>["refinance_goal"], null>
	) => {
		if (refinance_goal === "pay-off-mortgage")
			return "Pay off my mortgage faster";
		if (refinance_goal === "change-arm-to-fixed")
			return "Change my ARM loan to Fixed";
		if (refinance_goal === "cash-out") return "Take cash out";
		if (refinance_goal === "lower-payment") return "Lower my monthly payment";
		return refinance_goal;
	};

	const current_employment_status_value_to_label = (
		current_employment_status: Exclude<
			Required<Fields>["current_employment_status"],
			null
		>
	) => {
		if (current_employment_status === "employed") return "Employed";
		if (current_employment_status === "self-employed") return "Self-employed";
		if (current_employment_status === "retired") return "Retired";
		if (current_employment_status === "unemployed") return "Not employed";
		return current_employment_status;
	};

	const annual_income_range_value_to_label = (
		annual_income_range: Exclude<Required<Fields>["annual_income_range"], null>
	) => {
		if (annual_income_range === "less than $50,000") return "Less than $50,000";
		if (annual_income_range === "more than $200,000")
			return "More than $200,000";
		return annual_income_range;
	};

	const customer_provided_type_value_to_label = (
		customer_provided_type: Exclude<
			PropertyDetails["customer_provided_type"],
			null
		>
	) => {
		if (customer_provided_type === "single-family") return "Single Family";
		if (customer_provided_type === "multifamily") return "Multifamily";
		if (customer_provided_type === "condo") return "Condo";
		if (customer_provided_type === "manufactured") return "Manufactured";
		return customer_provided_type;
	};

	const customer_provided_use_value_to_label = (
		customer_provided_use: Exclude<
			PropertyDetails["customer_provided_use"],
			null
		>
	) => {
		if (customer_provided_use === "primary-residence")
			return "Primary Residence";
		if (customer_provided_use === "vacation-home") return "Vacation Home";
		if (customer_provided_use === "investment-property")
			return "Investment Property";
		return customer_provided_use;
	};

	export namespace API {
		export namespace GET {
			export type Response = Fields;
		}
		export namespace PATCH {
			export interface Request {
				property_id: number;
				payload?: Fields;
			}

			export type Response = {
				OK: "OK";
			};
		}
	}

	type SurveyValues = {
		refinance_monitoring?: Fields;
		property?: Partial<
			Pick<PropertyDetails, "customer_provided_type" | "customer_provided_use">
		>;
	};

	interface OptionalInterfaces {
		mortgage_rates?: MortgageRates.GetCurrent.Response["mortgage_rates"];
		property_details?: Partial<
			Pick<PropertyDetails, "id" | "q_primary_residence">
		>;
		property_equity?: Partial<
			Pick<
				IPropertyEquityContext,
				| "principal_remaining"
				| "principal_paid"
				| "last_sale_date_prop"
				| "mortgage_refinance_date"
				| "mortgage_interest_rate"
				| "mortgage_amount"
				| "mortgage_term"
				| "interest_paid"
				| "interest_remaining"
				| "next_payment"
			>
		>;
	}
	interface IContext
		extends SurveyBuilder.Flow.CompatibleInterface<
			SurveyValues,
			OptionalInterfaces
		> {}

	const initialContext: IContext = {
		tracking_values: {},
		survey_values: {},
		optional_interfaces: {},
		required_interfaces: {},
		survey_methods: {
			get: async () => {},
			update_backend: async () => {},
		},
		loading: true,
	};

	export const Context = React.createContext(initialContext);

	export const Provider: React.FC = ({ children }) => {
		const { details } = usePropertyContext();
		const { userData } = useAuthContext();
		const {
			principal_remaining,
			principal_paid,
			last_sale_date_prop,
			mortgage_refinance_date,
			mortgage_interest_rate,
			mortgage_amount,
			mortgage_term,
			interest_paid,
			interest_remaining,
			next_payment,
		} = usePropertyEquityContext();
		const { currentMortgageRateData } = useMortgageRates();

		const [loading, setLoading] = React.useState(true);
		const [survey_values, setSurveyValues] = React.useState<SurveyValues>({});

		const survey_methods = React.useMemo(
			() => ({
				get: async () => {
					if (!details?.id) return;
					try {
						setLoading(true);
						const { data: refinance_monitoring_fields } =
							await client.getRefinanceMonitoringFields({
								property_id: details.id,
							});
						const { data: property_fields } = await client.getProperty({
							id: details.id.toString(),
						});
						setSurveyValues({
							property: {
								customer_provided_type: property_fields?.customer_provided_type,
								customer_provided_use: property_fields?.customer_provided_use,
							},
							refinance_monitoring: refinance_monitoring_fields,
						});
					} catch (u: unknown) {
					} finally {
						setLoading(false);
					}
				},
				update_backend: async (inputs: {
					survey_values: DeepPartial<SurveyValues>;
				}) => {
					if (!details?.id) return;
					await Promise.all([
						client.patchProperty({
							property_id: details.id,
							payload: {
								...inputs.survey_values.property,
							},
						}),
						client.patchRefinanceMonitoringFields({
							property_id: details.id,
							payload: {
								...inputs.survey_values.refinance_monitoring,
							},
						}),
					]);
					setSurveyValues(prev => merge(prev, inputs.survey_values));
				},
			}),
			[details]
		);

		React.useEffect(() => {
			survey_methods.get();
		}, [survey_methods]);

		const context = {
			loading,
			tracking_values: {
				version: "account",
				property_id: details?.id,
				user_id: userData?.id,
			},
			survey_values,
			optional_interfaces: {
				mortgage_rates: currentMortgageRateData?.mortgage_rates,
				property_details: {
					id: details?.id,
					q_primary_residence: details?.q_primary_residence,
				},
				property_equity: {
					principal_remaining,
					principal_paid,
					last_sale_date_prop,
					mortgage_refinance_date,
					mortgage_interest_rate,
					mortgage_amount,
					mortgage_term,
					interest_paid,
					interest_remaining,
					next_payment,
				},
			},
			required_interfaces: {},
			survey_methods,
		};

		return <Context.Provider value={context}>{children}</Context.Provider>;
	};

	export const PublicProvider: React.FC = ({ children }) => {
		const [loading, setLoading] = React.useState(true);
		const [survey_values, setSurveyValues] = React.useState<SurveyValues>({});

		const survey_methods = React.useMemo(
			() => ({
				get: async () => {},
				update_backend: async (inputs: {
					survey_values: Partial<SurveyValues>;
				}) => {
					try {
						setSurveyValues(prev => ({
							refinance_monitoring: {
								...prev.refinance_monitoring,
								...inputs.survey_values.refinance_monitoring,
							},
							property: {
								...prev.property,
								...inputs.survey_values.property,
							},
						}));
					} catch (u: unknown) {
					} finally {
					}
				},
			}),
			[]
		);

		const context = {
			loading,
			tracking_values: {
				version: "public",
			},
			survey_values,
			optional_interfaces: {},
			required_interfaces: {},
			survey_methods,
		};

		return <Context.Provider value={context}>{children}</Context.Provider>;
	};

	export const useContext = () => {
		const context = React.useContext(Context);
		if (!context) {
			throw new Error(
				"RefinanceMonitoring.useContext must be used within a RefinanceMonitoring.Provider"
			);
		}
		return context;
	};

	export const useResults = () => {
		const { survey_values, optional_interfaces } = useContext();

		const { refinance_goal } = survey_values?.refinance_monitoring ?? {};

		const {
			next_payment: current_monthly_payment,
			mortgage_interest_rate: current_mortgage_interest_rate,
			mortgage_term: current_mortgage_term,
			principal_remaining: current_principal_remaining,
			interest_remaining: current_interest_remaining,
			mortgage_amount: current_mortgage_amount,
			last_sale_date_prop,
			mortgage_refinance_date,
		} = optional_interfaces?.property_equity ?? {};

		const current_mortgage_start_date =
			mortgage_refinance_date || last_sale_date_prop;

		const new_loan_interest_rate =
			optional_interfaces?.mortgage_rates?.["30_year_fixed_mortgage_rate"]
				?.value;

		return {
			refinance_goal,
			current_mortgage_start_date,
			current_monthly_payment,
			current_mortgage_interest_rate,
			current_mortgage_term,
			current_principal_remaining,
			current_interest_remaining,
			current_mortgage_amount,
			new_loan_interest_rate,
			...generateRefinanceSavingsResults({
				original_loan_start_date: current_mortgage_start_date,
				original_loan_interest_rate: current_mortgage_interest_rate,
				original_loan_amount: current_mortgage_amount,
				original_loan_term: current_mortgage_term,
				new_loan_term: 360,
				new_loan_amount: current_principal_remaining,
				new_loan_interest_rate: new_loan_interest_rate,
			}),
		};
	};

	export const generateSummaryTitle = ({
		refinance_goal,
		lower_payment,
	}: Pick<
		ReturnType<typeof useResults>,
		"refinance_goal" | "lower_payment"
	>) => {
		if (refinance_goal !== "lower-payment") {
			return `Thank you for submitting your information!`;
		}

		if (!!lower_payment) {
			return `There's a chance you could lower your mortgage payment if you refinance.`;
		}

		return `You're unlikely to lower your mortgage payment.`;
	};

	export const generateSummarySubtitle = ({
		refinance_goal,
		higher_payment,
		lower_payment,
		monthly_savings_after_refinance,
		total_interest_saved_after_refinance,
	}: Pick<
		ReturnType<typeof useResults>,
		| "refinance_goal"
		| "lower_payment"
		| "higher_payment"
		| "monthly_savings_after_refinance"
		| "total_interest_saved_after_refinance"
	>) => {
		if (refinance_goal !== "lower-payment") {
			return `We will reach out to you once we have support for your
				goal type. In the meantime, we'll show you how your
				mortgage would be impacted if you refinanced now.`;
		}

		return (
			<>
				Based on your existing mortgage details and new refinance numbers, you
				would pay about{" "}
				{monthly_savings_after_refinance && lower_payment && (
					<span className="kelly-dark semibold">
						{formatDollar(monthly_savings_after_refinance)}
						&nbsp;less&nbsp;per&nbsp;month
					</span>
				)}
				{monthly_savings_after_refinance && higher_payment && (
					<span className="rust-dark semibold">
						{formatDollar(Math.abs(monthly_savings_after_refinance))}
						&nbsp;more&nbsp;per&nbsp;month
					</span>
				)}{" "}
				and pay{" "}
				{total_interest_saved_after_refinance &&
					total_interest_saved_after_refinance > 0 && (
						<span className="kelly-dark semibold">
							{formatDollar(total_interest_saved_after_refinance)}
							&nbsp;less
						</span>
					)}
				{total_interest_saved_after_refinance &&
					total_interest_saved_after_refinance < 0 && (
						<span className="rust-dark semibold">
							{formatDollar(Math.abs(total_interest_saved_after_refinance))}
							&nbsp;more
						</span>
					)}{" "}
				in interest over a similar term loan.*
			</>
		);
	};

	export const generateServiceSummaryTitle = (
		params: Parameters<typeof generateSummaryTitle>[0]
	) => {
		if (params.refinance_goal !== "lower-payment") {
			return `We will reach out to you once we have support for your goal type.`;
		}
		return generateSummaryTitle(params);
	};

	export const generateServiceSummarySubtitle = (
		params: Parameters<typeof generateSummarySubtitle>[0]
	) => {
		if (params.refinance_goal !== "lower-payment") {
			return `We are working to provide support for these goals. Proceed and we will notify you when this goal is supported!`;
		}
		return generateSummarySubtitle(params);
	};

	export namespace Survey {
		const flowMachineConfig: SurveyBuilder.Flow.FlowMachineConfig<
			typeof Context
		> = {
			id: "REFINANCE_MONITORING",
			initial: "IDLE",
			context: initialContext,
			states: {
				IDLE: {
					on: {
						resume: {
							target: "ACTIVE_STEP",
						},
					},
				},
				ACTIVE_STEP: {
					always: [
						{
							target: "IDLE",
							guard: ({ context }) => {
								return Object.keys(context.survey_values).length === 0;
							},
						},
						{
							target: "INTRO",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.property?.customer_provided_type as any
								),
						},
						{
							target: "CUSTOMER_PROVIDED_TYPE",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.property?.customer_provided_type as any
								),
						},
						{
							target: "CUSTOMER_PROVIDED_USE",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.property?.customer_provided_use as any
								),
						},
						{
							target: "REFINANCE_GOAL",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.refinance_goal as any
								),
						},
						{
							target: "REMAINING_MORTGAGE_BALANCE",
							guard: ({ context, event }) =>
								[
									context.survey_values?.refinance_monitoring
										?.estimated_mortgage_balance as any,
									context.survey_values?.refinance_monitoring
										?.estimated_mortgage_balance_set_at as any,
								].some(v => [null, undefined].includes(v)),
						},
						{
							target: "IN_ESCROW",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring?.in_escrow as any
								),
						},
						{
							target: "VETERAN_OR_ACTIVE_DUTY",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.veteran_or_active_military as any
								),
						},
						{
							target: "CREDIT_SCORE_RANGE",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.credit_score_range as any
								),
						},
						{
							target: "HAS_SECOND_MORTGAGE",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.has_second_mortgage as any
								),
						},
						{
							target: "DECLARED_BANKRUPTCY",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.declared_bankruptcy_within_last_3_years as any
								),
						},
						{
							target: "CURRENT_EMPLOYMENT_STATUS",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.current_employment_status as any
								),
						},
						{
							target: "ANNUAL_INCOME",
							guard: ({ context, event }) =>
								[null, undefined].includes(
									context.survey_values?.refinance_monitoring
										?.annual_income_range as any
								),
						},
						{
							target: "REVIEW_DETAILS",
							guard: ({ context, event }) =>
								context.survey_values?.refinance_monitoring
									?.completed_survey === 0,
						},
						{
							target: "CUSTOMER_PROVIDED_TYPE",
						},
					],
				},
				INTRO: {
					entry: [
						assign(({ context, event }) => {
							return {
								progress: 0,
								is_step_valid: mc => true,
								NavigationRow: ({ TemplateNavigationRow }) => null,
								Step: function () {
									const { NextButton } = useFlow();
									const animationRef = React.useRef<SVGSVGElement>(null);
									React.useEffect(() => {
										if (!animationRef.current) return;
										lottie.loadAnimation({
											container: animationRef.current!,
											renderer: "svg",
											loop: true,
											autoplay: true,
											path: "/refi-monitoring-intro-animation.json",
											rendererSettings: {
												progressiveLoad: true,
											},
										});
									}, [animationRef]);

									return (
										<div className="flex flex-column">
											<p className="extra-large mb-3 desktop-mb-2 text-center">
												Are you overpaying on you your mortgage?
											</p>
											<svg
												ref={animationRef}
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 500 356"
												width="100%"
												height="100%"
												className="mx-auto mb-3 desktop-mb-2"
												style={{ maxHeight: "200px" }}
											/>
											<p className="lg mb-1 text-center">
												How refinance monitoring works:
											</p>
											<ul className="mx-auto mb-3 desktop-mb-4">
												<li className="mx-2_5 sm denim-medium">
													Free regular rate monitoring, so you don't have to
												</li>
												<li className="mx-2_5 sm denim-medium">
													We'll let you know if there are any savings you could
													take advantage of
												</li>
												<li className="mx-2_5 sm denim-medium">
													Easy to understand breakdown of how you compare
												</li>
												<li className="mx-2_5 sm denim-medium">
													Access to our refinancing calculator
												</li>
											</ul>
											<NextButton className="mx-auto">Continue</NextButton>
										</div>
									);
								},
							};
						}),
					],
					on: {
						skip_intro: {
							target: "CUSTOMER_PROVIDED_TYPE",
							actions: ["track", "set_direction_forward"],
						},
						next: {
							target: "CUSTOMER_PROVIDED_TYPE",
							actions: ["track", "set_direction_forward"],
						},
					},
				},
				CUSTOMER_PROVIDED_TYPE: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (0 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.property?.customer_provided_type as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();
									const customer_provided_type =
										machine_context?.survey_values?.property
											?.customer_provided_type;

									React.useEffect(() => {
										if (!customer_provided_type)
											update_machine_context?.({
												survey_values: {
													property: {
														customer_provided_type: "single-family",
													},
												},
											});
									}, []);

									return (
										<>
											<SurveyBuilder.Component.Title>
												Confirm your property type.
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Body>
												Confirming that we have the right property type on file
												helps create more accurate results.
											</SurveyBuilder.Component.Body>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="tiles"
												name="customer_provided_type"
												selection_state={customer_provided_type}
												set_selection_state={sv =>
													typeof sv === "string" &&
													update_machine_context?.({
														survey_values: {
															property: { customer_provided_type: sv },
														},
													})
												}
												options={[
													{
														selection_value: "single-family",
														label:
															customer_provided_type_value_to_label(
																"single-family"
															),
														Icon: (
															<SingleFamilyHomeSVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
													{
														selection_value: "multifamily",
														label:
															customer_provided_type_value_to_label(
																"multifamily"
															),
														Icon: (
															<MultifamilySVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
													{
														selection_value: "condo",
														label:
															customer_provided_type_value_to_label("condo"),
														Icon: (
															<CondoSVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
													{
														selection_value: "manufactured",
														label:
															customer_provided_type_value_to_label(
																"manufactured"
															),
														Icon: (
															<ManufacturedSVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CUSTOMER_PROVIDED_TYPE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.INTRO",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CUSTOMER_PROVIDED_TYPE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CUSTOMER_PROVIDED_USE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				CUSTOMER_PROVIDED_USE: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (1 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.property?.customer_provided_use as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();
									const q_primary_residence =
										machine_context?.optional_interfaces?.property_details
											?.q_primary_residence;
									const customer_provided_use =
										machine_context?.survey_values?.property
											?.customer_provided_use;

									React.useEffect(() => {
										if (!customer_provided_use && !!q_primary_residence)
											update_machine_context?.({
												survey_values: {
													property: {
														customer_provided_use: "primary-residence",
													},
												},
											});
									}, []);

									return (
										<>
											<SurveyBuilder.Component.Title>
												How do you use this property?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Body>
												This helps us find the right options for you as
												refinance rates vary depending the number of assets you
												have.
											</SurveyBuilder.Component.Body>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="tiles"
												name="customer_provided_use"
												selection_state={customer_provided_use}
												set_selection_state={sv =>
													typeof sv === "string" &&
													update_machine_context?.({
														survey_values: {
															property: { customer_provided_use: sv },
														},
													})
												}
												options={[
													{
														selection_value: "primary-residence",
														label:
															customer_provided_use_value_to_label(
																"primary-residence"
															),
														Icon: (
															<PrimaryResidenceSVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
													{
														selection_value: "vacation-home",
														label:
															customer_provided_use_value_to_label(
																"vacation-home"
															),
														Icon: (
															<VacationHomeSVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
													{
														selection_value: "investment-property",
														label: customer_provided_use_value_to_label(
															"investment-property"
														),
														Icon: (
															<InvestmentPropertySVG className="survey-builder-component-fields-radio-option-icon" />
														),
													},
												]}
											/>
											{customer_provided_use === "primary-residence" && (
												<SurveyBuilder.Component.Banners.Templates.Tip>
													Lenders view primary residence as the least risky.
												</SurveyBuilder.Component.Banners.Templates.Tip>
											)}
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CUSTOMER_PROVIDED_USE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CUSTOMER_PROVIDED_TYPE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CUSTOMER_PROVIDED_USE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.REFINANCE_GOAL",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				REFINANCE_GOAL: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (2 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.refinance_goal as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();
									const refinance_goal =
										machine_context?.survey_values?.refinance_monitoring
											?.refinance_goal;
									return (
										<>
											<SurveyBuilder.Component.Title>
												What would you like refinance monitoring to achieve?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Body>
												We can help guide you to optimize your goal. Please
												choose one.
											</SurveyBuilder.Component.Body>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="refinance_goal"
												selection_state={refinance_goal}
												set_selection_state={sv =>
													typeof sv === "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: { refinance_goal: sv },
														},
													})
												}
												options={[
													{
														selection_value: "lower-payment",
														label:
															refinance_goal_value_to_label("lower-payment"),
													},
													{
														selection_value: "pay-off-mortgage",
														label:
															refinance_goal_value_to_label("pay-off-mortgage"),
													},
													{
														selection_value: "change-arm-to-fixed",
														label: refinance_goal_value_to_label(
															"change-arm-to-fixed"
														),
													},
													{
														selection_value: "cash-out",
														label: refinance_goal_value_to_label("cash-out"),
													},
												]}
											/>
											{[
												"cash-out",
												"change-arm-to-fixed",
												"pay-off-mortgage",
											].includes(refinance_goal as any) && (
												<SurveyBuilder.Component.Banners.Sky>
													We are working to provide support for these goals.{" "}
													Proceed and we will notify you when this goal is
													supported.
												</SurveyBuilder.Component.Banners.Sky>
											)}
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "REFINANCE_GOAL",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CUSTOMER_PROVIDED_USE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "REFINANCE_GOAL",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.REMAINING_MORTGAGE_BALANCE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				REMAINING_MORTGAGE_BALANCE: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (3 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.estimated_mortgage_balance as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const estimated_mortgage_balance =
										machine_context?.survey_values?.refinance_monitoring
											?.estimated_mortgage_balance;
									const principal_remaining =
										machine_context?.optional_interfaces?.property_equity
											?.principal_remaining;
									const show_ownwell_estimate_badge =
										principal_remaining &&
										estimated_mortgage_balance &&
										Math.round(estimated_mortgage_balance) ===
											Math.round(principal_remaining);

									React.useEffect(() => {
										const value =
											estimated_mortgage_balance ?? principal_remaining;
										if (!!value)
											update_machine_context?.({
												survey_values: {
													refinance_monitoring: {
														estimated_mortgage_balance_set_at:
															moment().format("YYYY-MM-DD"),
														estimated_mortgage_balance: Math.round(value),
													},
												},
											});
									}, [principal_remaining]);
									// Exclude estimated_mortgage_balance fromd deps to allow user to delete entire pre-populated value

									return (
										<>
											<SurveyBuilder.Component.Title>
												What's your remaining mortgage balance?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Body>
												This is how much money you still need to pay to pay off
												your mortgage.
											</SurveyBuilder.Component.Body>
											{!principal_remaining && (
												<SurveyBuilder.Component.Banners.Slate>
													We couldn't find the mortgage balance for your
													property, please enter it down below. An estimate is
													okay!
												</SurveyBuilder.Component.Banners.Slate>
											)}
											<SurveyBuilder.Component.Fields.Jumbo.Dollar
												name="estimated_mortgage_balance"
												value={estimated_mortgage_balance}
												onChange={estimated_mortgage_balance =>
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: {
																estimated_mortgage_balance_set_at:
																	moment().format("YYYY-MM-DD"),
																estimated_mortgage_balance:
																	estimated_mortgage_balance,
															},
														},
													})
												}
												label={
													<p className="sm denim_5">
														Remaining Mortgage Balance
													</p>
												}
												suffix={
													show_ownwell_estimate_badge && (
														<SurveyBuilder.Component.Badges.Kelly>
															Ownwell Estimate
														</SurveyBuilder.Component.Badges.Kelly>
													)
												}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "REMAINING_MORTGAGE_BALANCE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.REFINANCE_GOAL",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "REMAINING_MORTGAGE_BALANCE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.IN_ESCROW",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				IN_ESCROW: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (4 / 11) * 100,
								is_step_valid: mc => {
									if (mc.survey_values?.refinance_monitoring?.in_escrow === 1) {
										const escrow_values = [
											mc.survey_values?.refinance_monitoring
												?.escrow_monthly_pmi_payment,
											mc.survey_values?.refinance_monitoring
												?.escrow_annual_property_taxes,
											mc.survey_values?.refinance_monitoring
												?.escrow_monthly_insurance_payment,
										];

										if (
											[
												mc.survey_values?.refinance_monitoring
													?.escrow_monthly_total_payment,
												escrow_values.every(v =>
													[null, undefined].includes(v as any)
												),
											].every(v => ![null, undefined].includes(v as any))
										)
											return true;

										if (
											escrow_values.every(
												v => ![null, undefined].includes(v as any)
											)
										)
											return true;
									}

									if (mc.survey_values?.refinance_monitoring?.in_escrow == 0)
										return true;

									return false;
								},
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const {
										in_escrow,
										escrow_monthly_total_payment,
										escrow_monthly_pmi_payment,
										escrow_annual_property_taxes,
										escrow_monthly_insurance_payment,
									} =
										machine_context?.survey_values?.refinance_monitoring ?? {};

									const [
										only_know_total_escrow_amount,
										set_only_know_total_escrow_amount,
									] = React.useState(!!escrow_monthly_total_payment);

									const show_escrow_total_field =
										!!only_know_total_escrow_amount;
									const show_escrow_breakdown_fields =
										!only_know_total_escrow_amount;

									return (
										<>
											<SurveyBuilder.Component.Title>
												Do you have an escrow account?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Fields.Radio.Group
												layout={"list"}
												name="in_escrow"
												selection_state={in_escrow}
												set_selection_state={sv =>
													typeof sv !== "boolean" &&
													typeof sv !== "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: { in_escrow: sv },
														},
													})
												}>
												<SurveyBuilder.Component.Fields.Radio.Option
													selection_value={1}>
													{({ selected }) => {
														const string_null_undefined_is_non_empty_string = (
															str: string | null | undefined
														): str is string => {
															return typeof str === "string" && str !== "";
														};

														const string_to_decimal_string_or_null = (
															str: string
														) => {
															const parsed_value = parseFloat(
																str.replace(/[^0-9.]/g, "")
															);
															return isNaN(parsed_value)
																? null
																: Math.round(parsed_value).toString();
														};

														const string_null_undefined_to_dollar_string = (
															value: string | null | undefined
														) => {
															return string_null_undefined_is_non_empty_string(
																value
															)
																? formatDollarStr(value)
																: "";
														};

														if (!selected) {
															return (
																<SurveyBuilder.Component.Fields.Radio.BulletWithLabel
																	selected={selected}
																	label={"Yes"}
																/>
															);
														}

														return (
															<div className="desktop-space-y-3 space-y-2">
																<SurveyBuilder.Component.Fields.Radio.BulletWithLabel
																	selected={selected}
																	label={"Yes"}
																/>
																<Checkbox
																	checked={!!only_know_total_escrow_amount}
																	onChange={e => {
																		if (e.target.checked) {
																			update_machine_context?.({
																				survey_values: {
																					refinance_monitoring: {
																						escrow_monthly_insurance_payment:
																							null,
																						escrow_monthly_pmi_payment: null,
																						escrow_annual_property_taxes: null,
																					},
																				},
																			});
																		} else {
																			update_machine_context?.({
																				survey_values: {
																					refinance_monitoring: {
																						escrow_monthly_total_payment: null,
																					},
																				},
																			});
																		}

																		set_only_know_total_escrow_amount(
																			e.target.checked
																		);
																	}}
																	label="I only know my total Escrow amount"
																	labelEl="p.sm"
																/>
																{show_escrow_total_field && (
																	<TextField
																		required
																		label="Total Monthly Escrow"
																		labelEl={createTooltipLabel({
																			label: "Total Monthly Escrow",
																			tooltip: EXPENSES_ESCROW_TOOLTIP,
																		})}
																		placeholder="Monthly escrow"
																		value={string_null_undefined_to_dollar_string(
																			escrow_monthly_total_payment
																		)}
																		innerRightLabel="/month"
																		onChange={e => {
																			update_machine_context?.({
																				survey_values: {
																					refinance_monitoring: {
																						escrow_monthly_total_payment:
																							string_to_decimal_string_or_null(
																								e.target.value
																							),
																					},
																				},
																			});
																		}}
																	/>
																)}
																{show_escrow_breakdown_fields && (
																	<>
																		<TextField
																			required
																			label="Monthly PMI"
																			labelEl={createTooltipLabel({
																				label: "Monthly PMI",
																				tooltip: EXPENSES_PMI_TOOLTIP,
																			})}
																			placeholder="Monthly PMI"
																			innerRightLabel="/month"
																			value={string_null_undefined_to_dollar_string(
																				escrow_monthly_pmi_payment
																			)}
																			onChange={e => {
																				update_machine_context?.({
																					survey_values: {
																						refinance_monitoring: {
																							escrow_monthly_pmi_payment:
																								string_to_decimal_string_or_null(
																									e.target.value
																								),
																						},
																					},
																				});
																			}}
																		/>
																		<div>
																			<TextField
																				required
																				label="Annual Property Tax"
																				labelEl={createTooltipLabel({
																					label: "Annual Property Tax",
																					tooltip:
																						EXPENSES_PROPERTY_TAX_TOOLTIP,
																				})}
																				placeholder="Annual Property Tax"
																				value={string_null_undefined_to_dollar_string(
																					escrow_annual_property_taxes
																				)}
																				innerRightLabel="/year"
																				onChange={e => {
																					update_machine_context?.({
																						survey_values: {
																							refinance_monitoring: {
																								escrow_annual_property_taxes:
																									string_to_decimal_string_or_null(
																										e.target.value
																									),
																							},
																						},
																					});
																				}}
																			/>
																			{string_null_undefined_is_non_empty_string(
																				escrow_annual_property_taxes
																			) && (
																				<div className="flex flex-gap-0_5 mt-1">
																					<p className="sm">
																						Monthly property tax
																					</p>
																					<p className="sm bold">
																						{formatDollar(
																							parseFloat(
																								escrow_annual_property_taxes
																							) / 12,
																							{ withCents: "when-necessary" }
																						)}
																						/mo
																					</p>
																				</div>
																			)}
																		</div>
																		<TextField
																			required
																			label="Home Insurance"
																			labelEl={createTooltipLabel({
																				label: "Monthly Insurance",
																				tooltip: EXPENSES_INSURANCE_TOOLTIP,
																			})}
																			placeholder="Monthly Insurance"
																			value={string_null_undefined_to_dollar_string(
																				escrow_monthly_insurance_payment
																			)}
																			innerRightLabel="/month"
																			onChange={e => {
																				update_machine_context?.({
																					survey_values: {
																						refinance_monitoring: {
																							escrow_monthly_insurance_payment:
																								string_to_decimal_string_or_null(
																									e.target.value
																								),
																						},
																					},
																				});
																			}}
																		/>
																	</>
																)}
																<QuickLink
																	size="small"
																	target="_blank"
																	href="/help/article/21174881525915-what-is-escrow-and-how-do-i-know-how-much-my-escrow-is">
																	What is Escrow and where do I find it?
																</QuickLink>
															</div>
														);
													}}
												</SurveyBuilder.Component.Fields.Radio.Option>
												<SurveyBuilder.Component.Fields.Radio.Option
													selection_value={0}>
													{({ selected }) => (
														<SurveyBuilder.Component.Fields.Radio.BulletWithLabel
															selected={selected}
															label={"No"}
														/>
													)}
												</SurveyBuilder.Component.Fields.Radio.Option>
											</SurveyBuilder.Component.Fields.Radio.Group>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "IN_ESCROW",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.REMAINING_MORTGAGE_BALANCE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "IN_ESCROW",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.VETERAN_OR_ACTIVE_DUTY",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				VETERAN_OR_ACTIVE_DUTY: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (5 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.veteran_or_active_military as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const veteran_or_active_military =
										machine_context?.survey_values?.refinance_monitoring
											?.veteran_or_active_military;
									return (
										<>
											<SurveyBuilder.Component.Title>
												Are you a veteran or active duty U.S. military?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="veteran_or_active_military"
												selection_state={veteran_or_active_military}
												set_selection_state={sv =>
													typeof sv !== "boolean" &&
													typeof sv !== "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: {
																veteran_or_active_military: sv,
															},
														},
													})
												}
												options={[
													{
														selection_value: 1 as 1,
														label: "Yes",
													},
													{
														selection_value: 0 as 0,
														label: "No",
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "VETERAN_OR_ACTIVE_DUTY",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.IN_ESCROW",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "VETERAN_OR_ACTIVE_DUTY",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CREDIT_SCORE_RANGE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				CREDIT_SCORE_RANGE: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (6 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.credit_score_range as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const credit_score_range =
										machine_context?.survey_values?.refinance_monitoring
											?.credit_score_range;
									return (
										<>
											<SurveyBuilder.Component.Title>
												What's your estimated FICO credit score?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Body>
												Your best guess is just fine.
											</SurveyBuilder.Component.Body>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="credit_score_range"
												selection_state={credit_score_range}
												set_selection_state={sv =>
													typeof sv === "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: { credit_score_range: sv },
														},
													})
												}
												options={[
													{ selection_value: "780+", label: "780+" },
													{ selection_value: "760-779", label: "760-779" },
													{ selection_value: "740-759", label: "740-759" },
													{ selection_value: "720-739", label: "720-739" },
													{ selection_value: "700-719", label: "700-719" },
													{ selection_value: "680-699", label: "680-699" },
													{ selection_value: "660-679", label: "660-679" },
													{ selection_value: "640-659", label: "640-659" },
													{ selection_value: "620-639", label: "620-639" },
													{ selection_value: "580-619", label: "580-619" },
													{
														selection_value: "579 or below",
														label: "579 or below",
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CREDIT_SCORE_RANGE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.VETERAN_OR_ACTIVE_DUTY",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CREDIT_SCORE_RANGE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.HAS_SECOND_MORTGAGE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				HAS_SECOND_MORTGAGE: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (7 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.has_second_mortgage as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const has_second_mortgage =
										machine_context?.survey_values?.refinance_monitoring
											?.has_second_mortgage;
									return (
										<>
											<SurveyBuilder.Component.Title>
												Do you have a second mortgage or HELOC?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="has_second_mortgage"
												selection_state={has_second_mortgage}
												set_selection_state={sv =>
													typeof sv !== "boolean" &&
													typeof sv !== "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: { has_second_mortgage: sv },
														},
													})
												}
												options={[
													{
														selection_value: 1 as 1,
														label: "Yes",
													},
													{
														selection_value: 0 as 0,
														label: "No",
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "HAS_SECOND_MORTGAGE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CREDIT_SCORE_RANGE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "HAS_SECOND_MORTGAGE",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.DECLARED_BANKRUPTCY",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				DECLARED_BANKRUPTCY: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (8 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.declared_bankruptcy_within_last_3_years as any
									),

								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const declared_bankruptcy_within_last_3_years =
										machine_context?.survey_values?.refinance_monitoring
											?.declared_bankruptcy_within_last_3_years;
									return (
										<>
											<SurveyBuilder.Component.Title>
												Have you declared bankruptcy in the last 3 years?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="declared_bankruptcy_within_last_3_years"
												selection_state={
													declared_bankruptcy_within_last_3_years
												}
												set_selection_state={sv =>
													typeof sv !== "boolean" &&
													typeof sv !== "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: {
																declared_bankruptcy_within_last_3_years: sv,
															},
														},
													})
												}
												options={[
													{
														selection_value: 1 as 1,
														label: "Yes",
													},
													{
														selection_value: 0 as 0,
														label: "No",
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "DECLARED_BANKRUPTCY",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.HAS_SECOND_MORTGAGE",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "DECLARED_BANKRUPTCY",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CURRENT_EMPLOYMENT_STATUS",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				CURRENT_EMPLOYMENT_STATUS: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (9 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.current_employment_status as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const current_employment_status =
										machine_context?.survey_values?.refinance_monitoring
											?.current_employment_status;
									return (
										<>
											<SurveyBuilder.Component.Title>
												What is your current employment status?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="current_employment_status"
												selection_state={current_employment_status}
												set_selection_state={sv =>
													typeof sv === "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: {
																current_employment_status: sv,
															},
														},
													})
												}
												options={[
													{
														selection_value: "employed",
														label:
															current_employment_status_value_to_label(
																"employed"
															),
													},
													{
														selection_value: "self-employed",
														label:
															current_employment_status_value_to_label(
																"self-employed"
															),
													},
													{
														selection_value: "retired",
														label:
															current_employment_status_value_to_label(
																"retired"
															),
													},
													{
														selection_value: "unemployed",
														label:
															current_employment_status_value_to_label(
																"unemployed"
															),
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CURRENT_EMPLOYMENT_STATUS",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.DECLARED_BANKRUPTCY",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "CURRENT_EMPLOYMENT_STATUS",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.ANNUAL_INCOME",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				ANNUAL_INCOME: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (10 / 11) * 100,
								is_step_valid: mc =>
									![null, undefined].includes(
										mc.survey_values?.refinance_monitoring
											?.annual_income_range as any
									),
								NavigationRow: ({ TemplateNavigationRow }) => (
									<TemplateNavigationRow />
								),
								Step: function () {
									const { update_machine_context, machine_context } = useFlow();

									const annual_income_range =
										machine_context?.survey_values?.refinance_monitoring
											?.annual_income_range;
									return (
										<>
											<SurveyBuilder.Component.Title>
												What is your annual income?
											</SurveyBuilder.Component.Title>
											<SurveyBuilder.Component.Body>
												Your income can affect your ability to qualify for a
												refinance, as it influences your debt-to-income ratio.
											</SurveyBuilder.Component.Body>
											<SurveyBuilder.Component.Fields.Radio.Templates.SolidBlocks
												layout="list"
												name="annual_income_range"
												selection_state={annual_income_range}
												set_selection_state={sv =>
													typeof sv === "string" &&
													update_machine_context?.({
														survey_values: {
															refinance_monitoring: { annual_income_range: sv },
														},
													})
												}
												options={[
													{
														selection_value: "less than $50,000",
														label:
															annual_income_range_value_to_label(
																"less than $50,000"
															),
													},
													{
														selection_value: "$50,000-$100,000",
														label:
															annual_income_range_value_to_label(
																"$50,000-$100,000"
															),
													},
													{
														selection_value: "$100,000-$150,000",
														label:
															annual_income_range_value_to_label(
																"$100,000-$150,000"
															),
													},
													{
														selection_value: "$150,000-$200,000",
														label:
															annual_income_range_value_to_label(
																"$150,000-$200,000"
															),
													},
													{
														selection_value: "more than $200,000",
														label:
															annual_income_range_value_to_label(
																"more than $200,000"
															),
													},
													{
														selection_value:
															"I do not wish to provide my income.",
														label: annual_income_range_value_to_label(
															"I do not wish to provide my income."
														),
													},
												]}
											/>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								back: {
									target: "clearing_answers",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						clearing_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "ANNUAL_INCOME",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.CURRENT_EMPLOYMENT_STATUS",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...context,
									state_value: "ANNUAL_INCOME",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.REVIEW_DETAILS",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				REVIEW_DETAILS: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: (11 / 11) * 100,
								is_step_valid: mc => true,
								NavigationRow: ({ TemplateNavigationRow }) => {
									const { RestartButton, NextButton } = useFlow();
									return (
										<>
											<RestartButton size="small">
												Edit Information
											</RestartButton>
											<NextButton size="small">Submit</NextButton>
										</>
									);
								},
								Step: function () {
									const { machine_context, RestartButton, NextButton } =
										useFlow();

									const { customer_provided_type, customer_provided_use } =
										machine_context?.survey_values?.property ?? {};

									const {
										refinance_goal,
										in_escrow,
										escrow_monthly_total_payment,
										escrow_monthly_pmi_payment,
										escrow_annual_property_taxes,
										escrow_monthly_insurance_payment,
										credit_score_range,
										current_employment_status,
										annual_income_range,
										estimated_mortgage_balance,
										veteran_or_active_military,
										declared_bankruptcy_within_last_3_years,
										has_second_mortgage,
									} =
										machine_context?.survey_values?.refinance_monitoring ?? {};

									return (
										<>
											<SurveyBuilder.Component.Title>
												Review Details
											</SurveyBuilder.Component.Title>
											<p className="denim-medium mb-2">
												We are almost done! Please review all the information
												here and make sure they are correct.
											</p>
											<div className="space-y-2">
												<SurveyBuilder.Component.Blocks.Block className="space-y-1">
													<Label className="denim_5">Goal</Label>
													{!!refinance_goal && (
														<p>
															{refinance_goal_value_to_label(refinance_goal)}
														</p>
													)}
												</SurveyBuilder.Component.Blocks.Block>
												<SurveyBuilder.Component.Blocks.Block className="space-y-1">
													<Label className="denim_5">Property Details</Label>
													<div className="space-y-1">
														{customer_provided_type && (
															<p>
																<strong>Property Type:</strong>{" "}
																{customer_provided_type_value_to_label(
																	customer_provided_type
																)}
															</p>
														)}
														{!!customer_provided_use && (
															<p>
																<strong>Use Type:</strong>{" "}
																{customer_provided_use_value_to_label(
																	customer_provided_use
																)}
															</p>
														)}
													</div>
												</SurveyBuilder.Component.Blocks.Block>
												<SurveyBuilder.Component.Blocks.Block className="space-y-1">
													<Label className="denim_5">Mortgage Details</Label>
													<div className="space-y-1">
														{typeof estimated_mortgage_balance === "number" && (
															<p>
																<strong>Remaining Mortgage:</strong>{" "}
																{formatDollar(estimated_mortgage_balance)}
															</p>
														)}
														{in_escrow === 1 &&
															typeof escrow_monthly_pmi_payment === "number" &&
															typeof escrow_annual_property_taxes ===
																"number" &&
															typeof escrow_monthly_insurance_payment ===
																"number" && (
																<p>
																	<strong>Escrow:</strong>{" "}
																	{formatDollar(
																		escrow_monthly_pmi_payment +
																			escrow_annual_property_taxes +
																			escrow_monthly_insurance_payment
																	)}
																	Monthly PMI:{" "}
																	{formatDollar(escrow_monthly_pmi_payment)}
																	Monthly Insurance:{" "}
																	{formatDollar(
																		escrow_monthly_insurance_payment
																	)}
																	Monthly Property Taxes:{" "}
																	{formatDollar(
																		escrow_annual_property_taxes / 12
																	)}
																</p>
															)}
														{in_escrow === 1 &&
															typeof escrow_monthly_total_payment ===
																"number" && (
																<p>
																	<strong>Escrow:</strong>{" "}
																	{formatDollar(escrow_monthly_total_payment)}
																</p>
															)}
														{has_second_mortgage === 1 && (
															<p>HELOC or Second Mortgage</p>
														)}
													</div>
												</SurveyBuilder.Component.Blocks.Block>
												<SurveyBuilder.Component.Blocks.Block className="space-y-1">
													<Label className="denim_5">Owner Details</Label>
													<div className="space-y-1">
														<p>
															<strong>Credit Range:</strong>{" "}
															{credit_score_range}
														</p>
														{!!current_employment_status && (
															<p>
																<strong>Employment Status:</strong>{" "}
																{current_employment_status_value_to_label(
																	current_employment_status
																)}
															</p>
														)}
														{!!annual_income_range && (
															<p>
																<strong>Income:</strong>{" "}
																{annual_income_range_value_to_label(
																	annual_income_range
																)}
															</p>
														)}
														{veteran_or_active_military === 1 && (
															<p>Veteran or Active Military</p>
														)}
														{declared_bankruptcy_within_last_3_years === 1 && (
															<p>Declared bankruptcy within last 3 years</p>
														)}
													</div>
												</SurveyBuilder.Component.Blocks.Block>
											</div>
											<p className="small mt-2">
												By clicking "Submit," you agree that the information
												you've provided may be used with third party partners to
												gather and provide opportunities to either save or
												achieve your goals. You will not be contacted by third
												party partners.
											</p>
										</>
									);
								},
							};
						}),
					],
					initial: "idle",
					states: {
						idle: {
							on: {
								restart: {
									target: "#REFINANCE_MONITORING.CUSTOMER_PROVIDED_TYPE",
									actions: ["track", "set_direction_backward"],
								},
								next: {
									target: "updating_answers",
									actions: ["track", "set_direction_forward"],
								},
							},
						},
						updating_answers: {
							entry: ["clear_error_message"],
							invoke: {
								id: "update_backend",
								src: "update_backend",
								input: ({ context }) => ({
									...merge(context, {
										survey_values: {
											refinance_monitoring: { completed_survey: 1 },
										},
									}),
									state_value: "REVIEW_DETAILS",
								}),
							},
							on: {
								callback_success: {
									target: "#REFINANCE_MONITORING.SUBMISSION_ANIMATION",
									actions: ["track"],
								},
								callback_error: {
									target: "idle",
									actions: ["track"],
								},
							},
						},
					},
				},
				SUBMISSION_ANIMATION: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: 100,
								is_step_valid: mc => true,
								NavigationRow: ({ TemplateNavigationRow }) => null,
								Step: function () {
									return (
										<div className="flex flex-column">
											<MortgageClipboardSVG
												className="mx-auto desktop-mt-7"
												style={{ minWidth: "240px", maxHeight: "240px" }}
											/>
											<SurveyBuilder.Component.Title className="mt-4 text-center">
												We are almost done..
											</SurveyBuilder.Component.Title>
											<div className="flex flex-column flex-gap-1">
												<motion.div
													className="flex items-center flex-gap-1 mx-auto"
													animate={{
														opacity: [0, 0, 1, 1, 1],
														transition: {
															duration: 3,
															times: [0, 0.25, 0.5, 0.75, 1],
														},
													}}>
													<p className="denim-medium">Reviewing answers</p>
													<CheckInKellyDarkCircleSVG
														style={{ width: "32px", height: "32px" }}
													/>
												</motion.div>
												<motion.div
													className="flex items-center flex-gap-1 mx-auto"
													animate={{
														opacity: [0, 0, 0, 1, 1],
														transition: {
															duration: 3,
															times: [0, 0.25, 0.5, 0.75, 1],
														},
													}}>
													<p className="denim-medium">Setting up monitoring</p>
													<CheckInKellyDarkCircleSVG
														style={{ width: "32px", height: "32px" }}
													/>
												</motion.div>
												<motion.div
													className="flex items-center flex-gap-1 mx-auto"
													animate={{
														opacity: [0, 0, 0, 0, 1],
														transition: {
															duration: 3,
															times: [0, 0.25, 0.5, 0.75, 1],
														},
													}}>
													<p className="denim-medium">
														Refinance monitoring activated
													</p>
													<CheckInKellyDarkCircleSVG
														style={{ width: "32px", height: "32px" }}
													/>
												</motion.div>
											</div>
										</div>
									);
								},
							};
						}),
					],
					after: {
						3000: {
							target: "RESULTS",
							actions: ["track"],
						},
					},
				},
				RESULTS: {
					entry: [
						"clear_error_message",
						assign(({ context, event }) => {
							return {
								progress: 100,
								is_step_valid: mc => true,
								NavigationRow: ({ TemplateNavigationRow }) => {
									const { CloseButton, machine_context } = useFlow();

									const RefiCalculatorButton = () => {
										const router = useRouter();
										const property_id =
											machine_context?.optional_interfaces?.property_details
												?.id;
										if (!property_id) return null;
										return (
											<ButtonV2
												size="small"
												onClick={() => {
													close?.();
													router.push(
														`/account/properties/${property_id}/refinance-calculator`
													);
												}}>
												Refinance Calculator
											</ButtonV2>
										);
									};

									return (
										<>
											<CloseButton variant="primary-outline" size="small">
												I'm Done
											</CloseButton>
											<RefiCalculatorButton />
										</>
									);
								},
								Step: function () {
									const CurrentLoanBox = () => {
										const {
											current_monthly_payment,
											current_mortgage_interest_rate,
											current_mortgage_term,
											current_interest_remaining,
											new_loan_interest_rate,
											monthly_payment_after_refinance,
											total_interest_after_refinance,
										} = useResults();

										return (
											<SurveyBuilder.Component.Blocks.Faded className="flex-grow-1">
												<Label className="mb-2">Current Loan</Label>
												<div className="space-y-1">
													{!!current_monthly_payment && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">
																Monthly Payment:
															</p>
															<p
																className={[
																	"extra-small",
																	!!monthly_payment_after_refinance
																		? current_monthly_payment.principal_payment +
																				current_monthly_payment.interest_payment >
																			monthly_payment_after_refinance
																			? "rust-dark"
																			: "kelly-dark"
																		: "",
																].join(" ")}>
																{formatDollar(
																	current_monthly_payment.interest_payment +
																		current_monthly_payment.principal_payment
																)}
															</p>
														</div>
													)}
													{!!current_mortgage_interest_rate && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">
																Interest Rate:
															</p>
															<p
																className={[
																	"extra-small",
																	!!new_loan_interest_rate
																		? parseFloat(
																				current_mortgage_interest_rate
																			) > new_loan_interest_rate
																			? "rust-dark"
																			: "kelly-dark"
																		: "",
																].join(" ")}>
																{parseFloat(current_mortgage_interest_rate) +
																	"%"}
															</p>
														</div>
													)}
													{!!current_mortgage_term && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">Loan Term:</p>
															<p className="extra-small">
																{current_mortgage_term % 12 === 0
																	? `${current_mortgage_term / 12} year`
																	: `${current_mortgage_term} month`}
															</p>
														</div>
													)}
													{!!current_interest_remaining && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">
																Remaining Interest:
															</p>
															<p
																className={[
																	"extra-small",
																	!!total_interest_after_refinance
																		? current_interest_remaining >
																			total_interest_after_refinance
																			? "rust-dark"
																			: "kelly-dark"
																		: "",
																].join(" ")}>
																{formatDollar(current_interest_remaining)}
															</p>
														</div>
													)}
												</div>
											</SurveyBuilder.Component.Blocks.Faded>
										);
									};

									const RefinancedLoanBox = () => {
										const {
											current_monthly_payment,
											current_mortgage_interest_rate,
											current_interest_remaining,
											new_loan_interest_rate,
											monthly_payment_after_refinance,
											total_interest_after_refinance,
										} = useResults();
										return (
											<SurveyBuilder.Component.Blocks.Faded className="flex-grow-1">
												<Label className="mb-2">Refinance Example</Label>
												<div className="space-y-1">
													{!!monthly_payment_after_refinance && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">
																New Monthly Payment:
															</p>
															<p
																className={[
																	"extra-small",
																	!!current_monthly_payment
																		? current_monthly_payment.principal_payment +
																				current_monthly_payment.interest_payment <
																			monthly_payment_after_refinance
																			? "rust-dark"
																			: "kelly-dark"
																		: "",
																].join(" ")}>
																{formatDollar(monthly_payment_after_refinance)}
															</p>
														</div>
													)}
													{!!new_loan_interest_rate && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">
																Interest Rate:
															</p>
															<p
																className={[
																	"extra-small",
																	!!current_mortgage_interest_rate
																		? parseFloat(
																				current_mortgage_interest_rate
																			) < new_loan_interest_rate
																			? "rust-dark"
																			: "kelly-dark"
																		: "",
																].join(" ")}>
																{new_loan_interest_rate + "%"}
															</p>
														</div>
													)}
													<div className="flex justify-between flex-gap-1">
														<p className="extra-small semibold">Loan Term:</p>
														<p className="extra-small">30 year</p>
													</div>
													{!!total_interest_after_refinance && (
														<div className="flex justify-between flex-gap-1">
															<p className="extra-small semibold">
																Total Interest:
															</p>
															<p
																className={[
																	"extra-small",
																	!!current_interest_remaining
																		? current_interest_remaining <
																			total_interest_after_refinance
																			? "rust-dark"
																			: "kelly-dark"
																		: "",
																].join(" ")}>
																{formatDollar(total_interest_after_refinance)}
															</p>
														</div>
													)}
												</div>
											</SurveyBuilder.Component.Blocks.Faded>
										);
									};

									const LoanComparison = () => {
										return (
											<div
												className="mx-auto w-full flex-column flex-gap-2 desktop-flex-row desktop-flex-gap-3 "
												style={{ maxWidth: "662px" }}>
												<CurrentLoanBox />
												<RefinancedLoanBox />
											</div>
										);
									};

									const Disclaimer = () => {
										return (
											<p className="mx-auto body-tiny denim-medium text-center">
												*This content is based on the information you've
												provided and only an estimate based on current market
												data available.
											</p>
										);
									};

									const PotentialSavings = () => {
										const { refinance_goal, monthly_savings_after_refinance } =
											useResults();
										if (refinance_goal !== "lower-payment") return null;
										if (
											!monthly_savings_after_refinance ||
											monthly_savings_after_refinance < 0
										)
											return null;
										return (
											<SurveyBuilder.Component.Badges.Kelly className="mx-auto">
												Potential Savings:{" "}
												{formatDollar(monthly_savings_after_refinance)}
												/month
											</SurveyBuilder.Component.Badges.Kelly>
										);
									};

									const Summary = () => {
										const results = useResults();
										return (
											<>
												<SurveyBuilder.Component.Title className="text-center">
													{generateSummaryTitle(results)}
												</SurveyBuilder.Component.Title>
												<SurveyBuilder.Component.Body className="text-center">
													{generateSummarySubtitle(results)}
												</SurveyBuilder.Component.Body>
											</>
										);
									};
									return (
										<div className="flex flex-column">
											<SurveyBuilder.Component.HouseWithCoins className="mx-auto" />
											<PotentialSavings />
											<Summary />
											<LoanComparison />
											<Disclaimer />
										</div>
									);
								},
							};
						}),
					],
				},
			},
			on: {
				set_direction_forward: {
					actions: ["set_direction_forward"],
				},
				set_direction_backward: {
					actions: ["set_direction_backward"],
				},
				set_error_message: {
					actions: ["set_error_message"],
				},
				update_machine_context: {
					actions: ["update_machine_context", "track"],
				},
				put_machine_context: {
					actions: ["put_machine_context"],
				},
				init: {
					target: ".IDLE",
				},
				close: {
					target: ".ACTIVE_STEP",
					actions: ["clear_error_message", "track", "exit"],
				},
			},
		};

		const flowMachine =
			SurveyBuilder.Flow.createFlowMachineFactory<
				typeof Context
			>().createMachine(flowMachineConfig);

		export const { FlowProvider, useFlow, FlowContext, FlowExit } =
			SurveyBuilder.Flow.build({
				reactContext: Context,
				flowMachine,
			});

		export const Flow = () => {
			return (
				<SurveyBuilder.Templates.SingleFlow.FullScreen
					FlowContext={FlowContext}
				/>
			);
		};

		export const EmbeddedFlow = () => {
			return (
				<SurveyBuilder.Templates.SingleFlow.Embedded
					FlowContext={FlowContext}
				/>
			);
		};
	}
}
