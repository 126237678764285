import {
	MouseEventHandler,
	useCallback,
	useEffect,
	useState,
	useMemo,
	Dispatch,
	SetStateAction,
	FC,
} from "react";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { useAuthContext } from "context/AuthContext";
import { useResponsiveContext } from "context/ResponsiveContext";
import Logo from "public/logo.svg";
import HamburgerIcon from "public/hamburger.svg";
import HamburgerCloseIcon from "public/cross.svg";
import Grid from "elements/Grid";
import Link from "next/link";
import statePages from "config/state-pages.json";
import { LanguagePickerDropdown } from "elements/LanguagePicker/LanguagePickerDropdown";
import GlobeIcon from "public/globe.svg";
import { LanguagePickerSwitch } from "elements/LanguagePicker/LanguagePickerSwitch";
import ArrowUp from "public/arrow-up.svg";
import ArrowDown from "public/arrow-down.svg";
import ArrowRight from "public/arrow-right-rounded.svg";
import { INavLink } from "utils/types";
import ButtonV2 from "elements/ButtonV2";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";

// !! -- IMPORTANT -- !!
//If making significant changes, don't forget to change the ExemptionHeader.tsx
interface LinkData {
	href: string;
	label: string;
}

export const navLinks: Record<string, LinkData | LinkData[]> = {
	Services: [
		{
			href: "/appeals",
			label: "Property Tax Appeals",
		},
		{
			href: "/exemptions",
			label: "Property Tax Exemptions",
		},
		{
			href: "/bills",
			label: "Bill Reduction",
		},
		{
			href: "/commercial",
			label: "Commercial Tax Appeals",
		},
		// {
		// 	href: "/insurance",
		// 	label: "Home Insurance",
		// },
	],
	"For Investors": [
		{
			href: "/investors",
			label: "Single Family Rentals",
		},
		{
			href: "/commercial",
			label: "Commercial Real Estate",
		},
	],
	About: {
		href: "/about",
		label: "About",
	},
	Pricing: {
		href: "/pricing",
		label: "Pricing",
	},
	Help: {
		href: "/help",
		label: "Help Center",
	},
};

const landingBNavLinks: Record<string, LinkData | LinkData[]> = {
	Services: [
		{
			href: "/appeals",
			label: "Property Tax Appeal",
		},
		{
			href: "/exemptions",
			label: "Property Tax Exemption",
		},
		{
			href: "/bills",
			label: "Bill Reduction",
		},
		{
			href: "/commercial",
			label: "Commercial Tax Appeals",
		},
		// {
		// 	href: "/insurance",
		// 	label: "Home Insurance",
		// },
	],
	"For Investors": [
		{
			href: "/investors",
			label: "Single Family Rentals",
		},
		{
			href: "/commercial",
			label: "Commercial Real Estate",
		},
	],
	About: {
		href: "/about",
		label: "About",
	},
	Pricing: {
		href: "/pricing",
		label: "Pricing",
	},

	Help: {
		href: "/help",
		label: "Help Center",
	},
};

const Header: React.FC = ({ children }) => {
	return (
		<HeaderComponent navLinksContents={landingBNavLinks}>
			{children}
		</HeaderComponent>
	);
};

const HeaderComponent: React.FC<{
	navLinksContents: Record<string, LinkData | LinkData[]>;
}> = ({ children, navLinksContents }) => {
	const { isAuthenticated, properties } = useAuthContext();
	const trackEvent = useEventTracker();

	const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
	const [scrollDirection, setScrollDirection] = useState<"up" | "down">("down");
	const [y, setY] = useState(0);

	const [darkNav, setDarkNav] = useState(false);

	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const { isMedium, isMobile } = useResponsiveContext();

	const initialExpanded = Object.keys(navLinksContents).reduce((prev, cur) => {
		return {
			...prev,
			[cur]: false,
		};
	}, {});

	const [expanded, setExpanded] =
		useState<Record<string, boolean>>(initialExpanded);

	const handleScroll = useCallback(
		e => {
			e.preventDefault();
			const window = e.currentTarget;
			if (y > window.scrollY && window.scrollY > 90) {
				setScrollDirection("up");
			} else if (y < window.scrollY) {
				// Allow scroll behind
				setScrollDirection("down");
				setExpanded(initialExpanded);
			}
			setY(window.scrollY);
		},
		[y]
	);

	const handleResize = useCallback(() => {
		setY(0);
	}, []);

	useEffect(() => {
		const currentPath = pathname;
		if (currentPath == "/commercial" || currentPath == "/investors") {
			setDarkNav(true);
		} else {
			setDarkNav(false);
		}
	}, [pathname]);

	useEffect(() => {
		setY(window.scrollY);
		window.addEventListener("scroll", handleScroll);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleResize);
		};
	}, [handleScroll, handleResize]);

	const goToSignIn = useCallback(() => {
		if (isAuthenticated) {
			router.push("/account/properties");
		} else {
			router.push("/sign-in");
		}
	}, [isAuthenticated, router]);

	const handleHamburgerClick: MouseEventHandler = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			setIsHamburgerOpen(p => !p);
		},
		[setIsHamburgerOpen]
	);

	useEffect(() => {
		const handler = () => {
			setExpanded(initialExpanded);
		};
		handler();
		return () => {
			handler();
		};
	}, [searchParams, pathname]);

	useEffect(() => {
		const clickAwayHandler = () => {
			setIsHamburgerOpen(false);
		};

		if (isHamburgerOpen) {
			window.addEventListener("click", clickAwayHandler);
			document.body.style.overflow = "hidden";
		} else {
			window.removeEventListener("click", clickAwayHandler);
			document.body.style.overflow = "auto";
		}

		return () => {
			window.removeEventListener("click", clickAwayHandler);
		};
	}, [isHamburgerOpen]);

	const { isDesktopLg, isDesktopNavSignedIn } = useResponsiveContext();

	useEffect(() => {
		if (
			(isHamburgerOpen && !isAuthenticated && isDesktopLg) ||
			(isAuthenticated && isDesktopNavSignedIn)
		) {
			setIsHamburgerOpen(false);
		}
	}, [isHamburgerOpen, isDesktopLg, isAuthenticated, isDesktopNavSignedIn]);

	const { accountLinkHref, accountLinkCopy } = useMemo(() => {
		if (!isAuthenticated) {
			return {
				accountLinkHref: "/sign-in",
				accountLinkCopy: "Sign In",
			};
		} else {
			return {
				accountLinkHref:
					properties.length === 1
						? "/account/properties/" + properties[0].id
						: "/account/properties",
				accountLinkCopy: "My Properties",
			};
		}
	}, [properties, isAuthenticated]);

	const appealCtaButtonCopy = useMemo(() => {
		if (statePages.NY.urls.find(url => "/" + url == pathname)) {
			return "Get Started";
		}
		return "Start Your Appeal";
	}, [pathname]);

	return (
		<div
			id="site-header-top"
			className={
				(isHamburgerOpen ? "site-header-top-mobile-hamburger-opened" : "") +
				(isAuthenticated ? " site-header-logged-in" : "")
			}>
			<div
				onClick={e => {
					e.stopPropagation();
				}}
				id={
					isMobile
						? "site-header-sticky"
						: isMedium
							? "site-header"
							: isHamburgerOpen
								? "site-header-sticky"
								: y < 90 && scrollDirection === "down"
									? "site-header"
									: scrollDirection === "down"
										? "site-header-absolute"
										: "site-header-sticky"
				}
				className={darkNav ? "site-header-dark" : "site-header-skyblue"}>
				{children}
				<Grid>
					<div id="site-logo">
						<Link
							href="/"
							passHref
							aria-label="Ownwell"
							id="ownwell-logo-container"
							className={darkNav ? "dark-theme-logo" : ""}>
							<Logo height="100%" width="100%" />
						</Link>
					</div>
					<div className="header-desktop-link-container">
						{Object.keys(navLinks).map(key =>
							Array.isArray(navLinks[key]) ? (
								<div
									className="flex nav-link-section-container"
									key={key + "-popover"}>
									<div
										className="nav-link"
										style={{ paddingBottom: "12px", marginBottom: "-12px" }}
										onMouseEnter={e => {
											e.stopPropagation();
											setExpanded(p => ({
												...p,
												[key]: true,
											}));
										}}
										onMouseLeave={() =>
											setExpanded(p => ({ ...p, [key]: false }))
										}>
										<div className="flex items-center">
											<p className={darkNav ? "dark-theme" : ""}>{key}</p>
											{expanded[key] ? (
												<ArrowUp
													id="nav-link-group-arrow-up"
													className={darkNav ? "fill-white" : ""}
												/>
											) : (
												<ArrowDown
													id="nav-link-group-arrow-down"
													className={darkNav ? "fill-white" : ""}
												/>
											)}
										</div>
										{expanded[key] && (
											<NavLinkPopover navKey={key} setExpanded={setExpanded} />
										)}
									</div>
								</div>
							) : (
								<div className="nav-link" key={key + "-link"}>
									<Link
										href={(navLinksContents[key] as LinkData).href}
										passHref>
										<p className={darkNav ? "dark-theme" : ""}>
											{(navLinksContents[key] as LinkData).label}
										</p>
									</Link>
								</div>
							)
						)}
					</div>
					<div className="header-btn-group">
						<Link passHref href={accountLinkHref}>
							<ButtonV2
								className={darkNav ? "white-outline" : ""}
								size="mobile-extra-small"
								variant="primary-outline"
								id="header-log-in-btn">
								{accountLinkCopy}
							</ButtonV2>
						</Link>
						<ButtonV2
							toAppealFlow={{
								referrer: "Clicks from header button cta (desktop)",
							}}
							size="mobile-extra-small"
							id="header-tablet-start-appeal-btn">
							{isAuthenticated ? "Add Property" : "Sign Up"}
						</ButtonV2>
						<LanguagePickerDropdown id="header-desktop-language-picker" />
					</div>
					<div
						className={
							"header-hamburger-container" + (darkNav ? " dark-theme" : "")
						}
						onClick={e => {
							e.stopPropagation();
						}}>
						{isHamburgerOpen ? (
							<HamburgerCloseIcon
								height="24px"
								width="24px"
								id="hamburger-close"
								className="header-hamburger"
								onClick={handleHamburgerClick}
							/>
						) : (
							<HamburgerIcon
								height="32px"
								width="32px"
								id="hamburger-open"
								className="header-hamburger"
								onClick={handleHamburgerClick}
							/>
						)}
					</div>
					{isHamburgerOpen && (
						<div id="header-hamburger-menu">
							<div id="header-hambuger-menu-top">
								<Grid>
									{Object.keys(navLinks).map(key =>
										Array.isArray(navLinks[key]) ? (
											<div className="hamburger-link-container" key={key}>
												<div className="flex justify-between">
													<p
														className={
															"hamburger-link" + (!expanded[key] ? " bold" : "")
														}>
														{key}
													</p>
													{!expanded[key] ? (
														<ArrowUp id="nav-link-group-arrow-up" />
													) : (
														<ArrowDown id="nav-link-group-arrow-down" />
													)}
												</div>
												{!expanded[key] && (
													<div className="hamburger-link-expanded-container">
														{(navLinks[key] as INavLink[]).map(n => (
															<div
																className="hamburger-link-container-inner"
																key={n.label + "-hamburger-link"}
																onClick={e => {
																	if (n.href === "/commercial") {
																		trackEvent({
																			eventName:
																				constants.EVENTS
																					.For_Investors_commercial_click,
																		});
																	}

																	if (n.href === "/investors") {
																		trackEvent({
																			eventName:
																				constants.EVENTS
																					.For_Investors_SFR_click,
																		});
																	}
																	handleHamburgerClick(e);
																}}>
																<Link href={n.href}>
																	<p className="hamburger-link">
																		{n.label}
																		<ArrowRight />
																	</p>
																</Link>
															</div>
														))}
													</div>
												)}
											</div>
										) : (
											<div
												className="hamburger-link-container"
												key={key + "-link-container"}>
												<Link
													href={(navLinksContents[key] as LinkData).href}
													className="hamburger-link"
													onClick={() => setIsHamburgerOpen(p => !p)}>
													<p className="hamburger-link">
														{(navLinksContents[key] as LinkData).label}
													</p>
												</Link>
											</div>
										)
									)}
									<div
										className="hamburger-link-container"
										id="hamburger-language-picker-container">
										<div className="hamburger-language-picker-copy-container">
											<span>
												<GlobeIcon />
											</span>
											<p className="sm denim-medium">Language:</p>
										</div>
										<LanguagePickerSwitch />
									</div>
								</Grid>
								<div className="hamburger-btn-container">
									<ButtonV2
										size="mobile-extra-small"
										className="hamburger-btn"
										toAppealFlow={{
											referrer: "Clicks from header button cta (mobile)",
										}}>
										{isAuthenticated ? "Add Property" : "Sign Up"}
									</ButtonV2>
									<Link
										href="/sign-in"
										passHref
										onClick={() => setIsHamburgerOpen(p => !p)}
										className="hamburger-btn">
										<ButtonV2
											size="mobile-extra-small"
											id="hamburger-login-btn"
											variant="primary-outline"
											onClick={goToSignIn}>
											{isAuthenticated ? "My Properties" : "Sign In"}
										</ButtonV2>
									</Link>
								</div>
							</div>
						</div>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default Header;

const NavLinkPopover: FC<{
	navKey: string;
	setExpanded: Dispatch<SetStateAction<Record<string, boolean>>>;
}> = ({ navKey, setExpanded }) => {
	const trackEvent = useEventTracker();

	useEffect(() => {
		const handler = () => {
			setExpanded(p => ({
				...p,
				[navKey]: !p[navKey],
			}));
		};

		window.addEventListener("click", handler);

		return () => {
			window.removeEventListener("click", handler);
		};
	}, []);

	return (
		<div className="nav-link-section-popover">
			{(navLinks[navKey] as LinkData[]).map((nl, i) => (
				<Link
					href={nl.href}
					key={nl.label + "-popover-" + i}
					onClick={() => {
						if (nl.href === "/commercial") {
							trackEvent({
								eventName: constants.EVENTS.For_Investors_commercial_click,
							});
						}

						if (nl.href === "/investors") {
							trackEvent({
								eventName: constants.EVENTS.For_Investors_SFR_click,
							});
						}
					}}>
					<div className="nav-link-vertical">
						<div className="nav-link-vertical-nav-container">
							<p className="royal">{nl.label}</p>
							<ArrowRight />
						</div>
					</div>
				</Link>
			))}
		</div>
	);
};
