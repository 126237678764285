import { Image, TextField } from "components";
import {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import client from "utils/client";
import { formatDollarStr } from "utils/formatDollar";
import { usePropertyContext } from "components/pages/Account/Property/context";
import ErrorIcon from "public/error-icon.svg";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import { PropertySurveyEvidenceModal } from "../PropertySurveyEvidenceModal";
import ClosingDocsExample1 from "public/evidence-examples/closing-docs1.png";
import ClosingDocsExample2 from "public/evidence-examples/closing-docs2.png";
import ExitAreYouSure from "components/pages/Appeal/ExitAreYouSure";
import TimeForward from "public/time-forward.svg";
import QuestionCard from "elements/QuestionCard";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import TextArea from "elements/Input/TextArea";
import { TileSelect } from "elements/TileSelect";
import { FileData, OneZeroOrNull } from "utils/types";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import { FileUploadType, splitFileUploads } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const PurchaseDate = () => {
	const {
		details,
		next,
		send,
		activeQuestionId,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [purchaseDateError, setPurchaseDateError] = useState(false);

	const [purchaseDate, setPurchaseDate] = useState(
		propertySurveyContext?.last_sale_date_prop
	);
	const [purchasePrice, setPurchasePrice] = useState(
		propertySurveyContext?.last_sale_price_prop
	);

	const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const [skipModalOpen, setSkipModalOpen] = useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("purchasedRecently")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const handleTooltipClick = () => {
		setEvidenceModalOpen(true);
	};

	const handleSubmit = async () => {
		if (purchaseDate?.length !== 10 || purchaseDate === "00/00/0000") {
			setPurchaseDateError(true);
			return;
		}

		send({
			type: "update_context",
			context: {
				last_sale_date_prop: purchaseDate,
				last_sale_price_prop: purchasePrice,
			},
		});

		setSubmitting(true);
		setError(false);

		const payload = {
			value: true,
			price: purchasePrice?.replace(/[^\d.]/g, "") || undefined,
			date: purchaseDate
				? new Date(purchaseDate).toISOString().slice(0, 10)
				: undefined,
		};

		try {
			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload,
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("purchasedRecently", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "purchasedRecently-" + fileToUpload.file.name,
									question: "purchasedRecently",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			if (!filesUploaded) {
				setSkipModalOpen(true);
			} else {
				next();
			}
		} catch (e) {
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	useEffect(() => {
		if (propertySurveyContext?.last_sale_date_prop?.length === 10) {
			setPurchaseDateError(false);
		}
	}, [propertySurveyContext?.last_sale_date_prop]);

	const btnDisabled = useMemo(() => {
		const dateIsValid = purchaseDate?.length === 10;
		return submitting || !dateIsValid || !purchasePrice;
	}, [submitting, purchaseDate, purchasePrice]);

	const handleSkip = () => {
		if (!filesUploaded) {
			setSkipModalOpen(true);
		} else {
			next();
		}
	};

	const showDocumentsRecommendedWarning = useMemo(() => {
		if ((purchaseDate !== null || purchasePrice !== null) && !filesUploaded) {
			return true;
		}
		return false;
	}, [purchaseDate, purchasePrice, filesUploaded]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			{evidenceModalOpen && <EvidenceModal setOpen={setEvidenceModalOpen} />}
			<>
				<QuestionCard
					id="purchase-date"
					tooltipText="The recent purchase of your property is one of the most important factors in our ability to lower your assessment."
					question={<p className="lg bold">Property Purchase Information</p>}
					subTitle="A closing disclosure must include a property purchase price, date, signature, and realtor commissions."
					filesLabel="Closing Disclosure"
					withFiles
					alreadyUploaded={questionFiles}
					filesToUpload={filesToUpload}
					setFilesToUpload={setFilesToUpload}
					onTooltipClick={() => handleTooltipClick()}
					withRadio={false}>
					<TextField
						required
						placeholder="MM/DD/YYYY"
						minLength={10}
						maxLength={10}
						label="Purchase Date"
						value={purchaseDate ?? ""}
						containerClassName="profile-purchase-date"
						onChange={e => {
							const val = formatDate(e, purchaseDate ?? "");
							const valid = isValidDate(val);

							if (valid) {
								setPurchaseDate(val);
							}

							if (val.length === 10) {
								e.target.setCustomValidity("");
							} else {
								e.target.setCustomValidity(
									"Please enter a valid date in the format MM/DD/YYYY."
								);
							}
						}}
						onFocus={e => {
							const val = formatDate(e, purchaseDate ?? "");
							if (val === "00/00/0000") {
								setPurchaseDate("");
							}
						}}
					/>
					{purchaseDateError && (
						<p className="error-message sm">
							<ErrorIcon className="login-error-icon" />
							Error: Please enter a valid date
						</p>
					)}
					<TextField
						placeholder="$"
						required
						minLength={2}
						label="Total Purchase Price"
						containerClassName="profile-purchase-price"
						value={formatDollarStr(purchasePrice ?? "")}
						onChange={e => {
							const val = formatDollarStr(e.target.value);

							if (val?.replace(/\D/g, "")) {
								setPurchasePrice(val);
							} else {
								setPurchasePrice(null);
							}
						}}
					/>
				</QuestionCard>
			</>
			{purchaseDateError && (
				<p className="error-message sm">
					<ErrorIcon className="login-error-icon" />
					Error: Please scroll up and enter a valid Purchase Date
				</p>
			)}
			{showDocumentsRecommendedWarning && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				type="submit"
				disabled={btnDisabled}
				className="mx-auto">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
			{skipModalOpen && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Evidence submitted without documentation rarely results in a
								reduction.
							</p>

							<br />
							<p>
								Please provide supporting documentation now or at a later time
								via your property portal or email.
							</p>
						</div>
					}
					exitBtnContent={
						<>
							<TimeForward />
							<p>Add Info Later</p>
						</>
					}
					onExit={() => {
						setSkipModalOpen(false);
						next(true);
					}}
					onCancel={() => {
						setSkipModalOpen(false);
					}}
				/>
			)}
		</form>
	);
};

export const EvidenceModal: FC<{
	setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setOpen }) => {
	return (
		<PropertySurveyEvidenceModal onAttemptExit={() => setOpen(false)}>
			<p>
				<b>Closing Disclosure</b>
			</p>
			<div className="property-survey-evidence-modal-body-text-container">
				<p className="sm">
					If you recently purchased this property and the county assessor does
					not know the purchase price they will assume the purchase price is
					higher than your assessment. This makes it difficult or impossible to
					earn a tax reduction. By uploading your closing disclosure, we can
					fight to lower your assessment to your purchase price. We will never
					share documents with the assessor’s office that hurts your chance of a
					tax reduction.
				</p>
				<br />
				<p className="sm">
					<b>Uploaded closing disclosure must include:</b>
				</p>
				<ul>
					<li className="sm">
						<b>Property Purchase Price</b>
					</li>
					<li className="sm">
						<b>Dated</b>
					</li>
					<li className="sm">
						<b>Signature</b>
					</li>
					<li className="sm">
						<b>Realtor Commissions</b>
					</li>
				</ul>
			</div>
			<p>Example Documents & Photos</p>
			<div className="property-survey-evidence-modal-image-container">
				<Image
					// openInNewTab
					placeholder="blur"
					src={ClosingDocsExample1}
					alt="Closing disclosure documents example image"
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={ClosingDocsExample2}
					alt="Second closing disclosure documents example image"
				/>
			</div>
			<ButtonV2
				size="mobile-extra-small"
				onClick={() => setOpen(false)}
				className="mx-auto">
				Back to Question
			</ButtonV2>
		</PropertySurveyEvidenceModal>
	);
};

export const ArmsLengthTransaction = () => {
	const { details, send, next, activeQuestionId, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next();
	};

	const handleSubmit = async (payload?: { value: OneZeroOrNull }) => {
		setSubmitting(true);
		setError(false);

		if (payload) {
			send({
				type: "update_context",
				context: { question_armslength: payload.value },
			});
		}

		try {
			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					value: !!(
						payload?.value ?? propertySurveyContext.question_armslength
					),
				},
			});

			next();
		} catch (e) {
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes",
				selectedTileContent: "Yes",
				isSelected: propertySurveyContext.question_armslength === 1,
				onSelect: () => {
					handleSubmit({ value: 1 });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.question_armslength === 0,
				onSelect: () => {
					handleSubmit({ value: 0 });
				},
			},
		],
		[propertySurveyContext.question_armslength]
	);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<>
				<QuestionCard
					id="arms-length-transaction"
					tooltipText="Whether or not your property was purchased through an arm's length transaction changes how we approach building your case."
					question={
						<p className="lg bold">
							Was this property purchased or transferred from a family member,
							friend, or company that you have a conflict of interest with?
						</p>
					}>
					<TileSelect tiles={tiles} />
				</QuestionCard>
			</>
			<ButtonV2
				size="large"
				type="submit"
				disabled={propertySurveyContext.question_armslength === null}>
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export const ArmsLengthTransactionDescription = () => {
	const { details, send, next, activeQuestionId, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next();
	};

	const handleSubmit = async () => {
		setSubmitting(true);
		setError(false);

		try {
			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					desc: propertySurveyContext.question_armslength_desc,
				},
			});

			next();
		} catch (e) {
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="arms-length-transaction-description"
				tooltipText="Whether or not your property was purchased through an arm's length transaction changes how we approach building your case."
				question={
					<p className="lg bold">
						Please describe the nature of the transaction.
					</p>
				}>
				<TextArea
					required
					label="Description of Transaction"
					placeholder="Inheritance, trust transfer, etc."
					value={propertySurveyContext.question_armslength_desc ?? ""}
					onChange={e => {
						send({
							type: "update_context",
							context: {
								question_armslength_desc: e.target.value,
							},
						});
					}}
				/>
			</QuestionCard>
			<ButtonV2
				size="large"
				type="submit"
				disabled={!propertySurveyContext.question_armslength_desc}>
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default PurchaseDate;
