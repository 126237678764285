"use client";
import { useRouter, usePathname } from "next/navigation";
import {
	targettedValuePropItems,
	newYorkTargettedValuePropItems,
	landingPageBValuePropItems,
} from "./ValueProps2";
import { cardItemsBTest, targetCardItems } from "../shared/ValuePropCards";
import { StatesWithStatePage } from "utils/types";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import statePages from "config/state-pages.json";
import { useEffect, useMemo } from "react";
import { useAuthContext } from "context/AuthContext";
import { AbTest } from "elements/AbTest";
import AnnouncementBanner from "elements/AnnouncementBanner";
import RealtorReferralPartnerBanner from "./RealtorReferralPartnerBanner";
import Script from "next/script";
import Head from "next/head";
import { HeroSection } from "./HeroSection";
import { MarqueeFeaturedSection } from "./MarqueeFeaturedSection";
import { StatSection } from "./StatSection";
import { BigNumberCounterSection } from "./BigNumberCounterSection";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { PartnersBanner } from "./PartnersBanner";
import dynamic from "next/dynamic";

import type { MoreWaysToSaveSectionProps } from "./MoreWaysToSaveSection";
import type { TestimonialsSectionProps } from "./TestimonialsSection";
import type { SignUpStepsSectionProps } from "./SignUpStepsSection";
import type { PropertyTax101SectionProps } from "./PropertyTax101Section";
import type { ViewPricingSectionProps } from "./ViewPricingSection";
import type { ExpertsSectionProps } from "./ExpertsSection";
import type { FAQSectionProps } from "./FAQSection";
import { DynamicLandingPageProps } from "utils/types";

const LazyMoreWaysToSave = dynamic<MoreWaysToSaveSectionProps>(
	() =>
		import("./MoreWaysToSaveSection").then(
			module => module.MoreWaysToSaveSection
		),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyTestimonialsSection = dynamic<TestimonialsSectionProps>(
	() =>
		import("./TestimonialsSection").then(module => module.TestimonialsSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazySignUpStepsSection = dynamic<SignUpStepsSectionProps>(
	() =>
		import("./SignUpStepsSection").then(module => module.SignUpStepsSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyPropertyTax101Section = dynamic<PropertyTax101SectionProps>(
	() => import("./PropertyTax101Section").then(module => module.default),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyViewPricingSection = dynamic<ViewPricingSectionProps>(
	() =>
		import("./ViewPricingSection").then(module => module.ViewPricingSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyExpertsSection = dynamic<ExpertsSectionProps>(
	() => import("./ExpertsSection").then(module => module.ExpertsSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyFAQSection = dynamic<FAQSectionProps>(
	() => import("./FAQSection").then(module => module.FAQSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const STATES_WITH_COUNTIES_BANNER = ["GA", "FL"];

const HAS_BEEN_ON_LANDING_PAGE = "shown_landing_page";

const STATES_WITH_NO_PROPERTY_TAX_PAGE = ["NY", "IL"];

const LandingFancy = ({
	target,
	dynamicElements,
}: {
	target?: StatesWithStatePage;
	dynamicElements?: DynamicLandingPageProps["dynamicElements"];
}) => {
	const { isAuthenticated } = useAuthContext();
	const trackEvent = useEventTracker();
	const router = useRouter();
	const pathname = usePathname();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.HOME_PAGE_VISIT,
			data: {
				Variant: target ? target : "false",
			},
		});
	}, []);

	const hasBeenOnLandingPage = useMemo(() => {
		try {
			if (typeof window === "undefined" || window.sessionStorage === undefined)
				return false;
			return !!window.sessionStorage.getItem(HAS_BEEN_ON_LANDING_PAGE);
		} catch {
			console.error("Cannot access sessionStorage");
		}
	}, []);

	useEffect(() => {
		try {
			if (
				isAuthenticated &&
				!hasBeenOnLandingPage &&
				window.sessionStorage !== undefined
			) {
				window.sessionStorage.setItem(HAS_BEEN_ON_LANDING_PAGE, "1");
				router.push("/account/properties");
			}
		} catch {
			console.error("Cannot access sessionStorage");
		}
	}, [hasBeenOnLandingPage, isAuthenticated]);

	if (target && !statePages.hasOwnProperty(target)) target = undefined;

	const valuePropItems =
		target === "NY"
			? newYorkTargettedValuePropItems
			: target
				? targettedValuePropItems
				: landingPageBValuePropItems;

	const valuePropCards = useMemo(() => {
		if (target === "GA" || target === "WA") {
			return targetCardItems;
		} else {
			return cardItemsBTest;
		}
	}, [target]);

	return (
		<>
			<div id="landing-fancy">
				<HeroSection
					title={
						dynamicElements?.title
							? dynamicElements.title
							: `Save money on your ${
									target ? statePages[target].name + " " : ""
								}property taxes`
					}
					isTarget={!!target}
				/>
				<div className="cloud-gradient-sections">
					<MarqueeFeaturedSection />
					<StatSection />
				</div>
				{dynamicElements?.showBelowFold !== false && (
					<>
						<BigNumberCounterSection />
						<div className="sky-light-gradient-sections">
							<LazyMoreWaysToSave target={target} />
							<LazyTestimonialsSection />
							<LazySignUpStepsSection />
							{target && !STATES_WITH_NO_PROPERTY_TAX_PAGE.includes(target) && (
								<LazyPropertyTax101Section target={target} />
							)}
						</div>
						<LazyViewPricingSection />
						<LazyExpertsSection />
						<LazyFAQSection />
					</>
				)}
			</div>
		</>
	);
};

export default LandingFancy;
