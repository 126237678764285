"use client";
import React, { useEffect, useState } from "react";
import ConciergeOptionTab from "./ConciergeOptionTab";
import ConciergeOptionCard from "./ConciergeOptionCard";
import ConciergeProOptionCard from "./ConciergeProOptionCard";
import NoConciergeOptionCard from "./NoConciergeOptionCard";
import { usePropertyContext } from "../context";
import { usePathname, useRouter } from "next/navigation";
import client from "utils/client";
import ButtonV2 from "elements/ButtonV2";
import { useAuthContext } from "context/AuthContext";
import BackIcon from "public/account/pagination/angle-left.svg";
import ConciergeConfirmationContent from "./ConciergeConfirmation";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import PropertyModal from "../Modal";
import Warning from "public/account/survey/concierge/warning.svg";
import CircleCheck from "public/account/survey/concierge/check-circle-green.svg";
import ConciergePayment from "../Survey/questions/ConciergePayment";

export interface IConciergeContext {
	billingCycle: string;
	setBillingCycle: React.Dispatch<React.SetStateAction<string>>;
	conciergePlan: string;
	setConciergePlan: React.Dispatch<React.SetStateAction<string>>;
	conciergePaid: boolean;
	submitPayment: (
		card_number: string,
		card_cvc: string,
		card_exp_month: string,
		card_exp_year: string
	) => Promise<void>;
	paymentConfirmed: boolean;
	paymentErrored: boolean;
	setPaymentErrored: React.Dispatch<React.SetStateAction<boolean>>;
	paymentSubmitting: boolean;
	cancelSubscription: () => Promise<void>;
	cancellationConfirmed: boolean;
	cancellationErrored: boolean;
	cancelling: boolean;
	downgradeAccountTier: () => Promise<void>;
	downgradingConfirmed: boolean;
	downgradingErrored: boolean;
	downgrading: boolean;
}

export const ConciergeContext = React.createContext<IConciergeContext>({
	billingCycle: "annually",
	setBillingCycle: () => {},
	conciergePlan: "",
	setConciergePlan: () => {},
	conciergePaid: false,
	submitPayment: async (
		card_number,
		card_cvc,
		card_exp_month,
		card_exp_year
	) => {},
	paymentConfirmed: false,
	paymentErrored: false,
	setPaymentErrored: () => {},
	paymentSubmitting: false,
	cancelSubscription: async () => {},
	cancellationConfirmed: false,
	cancellationErrored: false,
	cancelling: false,
	downgradeAccountTier: async () => {},
	downgradingConfirmed: false,
	downgradingErrored: false,
	downgrading: false,
});

export const useConciergeContext = () => React.useContext(ConciergeContext);

export const ConciergeContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const { userData, setUserData } = useAuthContext();

	const [conciergePlan, setConciergePlan] = useState("");
	const [billingCycle, setBillingCycle] = useState("annually");
	const [conciergePaid, setConciergePaid] = useState(false);

	const [cancellationConfirmed, setCancellationConfirmed] = useState(false);
	const [cancellationErrored, setCancellationErrored] = useState(false);
	const [cancelling, setCancelling] = useState(false);

	const [downgradingErrored, setDowngradingErrored] = useState(false);
	const [downgradingConfirmed, setDowngradingConfirmed] = useState(false);
	const [downgrading, setDowngrading] = useState(false);

	const [paymentConfirmed, setPaymentConfirmed] = useState(false);
	const [paymentErrored, setPaymentErrored] = useState(false);
	const [paymentSubmitting, setPaymentSubmitting] = useState(false);

	const trackEvent = useEventTracker();

	useEffect(() => {
		const activeConciergeSubsription = userData?.active_subscription.find(sub =>
			sub.product.includes("concierge")
		);
		if (activeConciergeSubsription) {
			setConciergePaid(true);
		} else {
			setConciergePaid(false);
		}
	}, [userData?.active_subscription]);

	const submitPayment = async (
		card_number: string,
		card_cvc: string,
		card_exp_month: string,
		card_exp_year: string
	) => {
		try {
			setPaymentSubmitting(true);
			const response = await client.payConciergeInvoice({
				product: conciergePlan,
				cadence: billingCycle,
				card_number,
				card_cvc,
				card_exp_month,
				card_exp_year,
			});
			if (response.status !== 200) {
				setPaymentErrored(true);
				console.error("Failed to pay invoice");
				return;
			} else {
				const userData = await client.getUserData();
				setUserData(userData);
				setPaymentConfirmed(true);
			}
		} catch (err) {
			setPaymentErrored(true);
			console.error(err);
		} finally {
			setPaymentSubmitting(false);
		}
	};

	const cancelSubscription = async () => {
		const subscriptionId =
			userData?.active_subscription.find(sub =>
				sub.product.includes("concierge")
			)?.subscriptionId || null;
		if (subscriptionId) {
			setCancelling(true);
			//cancelling
			try {
				const response = await client.cancelConciergeSubsription({
					subscription_id: subscriptionId,
				});
				if (response.status === 200) {
					const userData = await client.getUserData();
					setUserData(userData);
					setCancellationConfirmed(true);
					trackEvent({
						eventName:
							constants.EVENTS.Concierge_cancellation_after_payment_action,
						data: {
							CustomerId: userData?.id,
							action: "Yes Cancel",
						},
					});
				} else setCancellationErrored(true);
			} catch (error) {
				setCancellationErrored(true);
			} finally {
				setCancelling(false);
			}
		}
	};

	const downgradeAccountTier = async () => {
		try {
			setDowngrading(true);
			const response = await client.downgradeConciergeSubsription();
			if (response.status === 200) {
				setDowngradingConfirmed(true);
				trackEvent({
					eventName:
						constants.EVENTS.Concierge_onboarding_upgrade_payment_action,
					data: {
						CustomerId: userData?.id,
						action: "cancel",
					},
				});
			} else setDowngradingErrored(true);
		} catch (error) {
			setDowngradingErrored(true);
			console.error("Error downgrading account tier", error);
		} finally {
			setDowngrading(false);
		}
	};

	return (
		<ConciergeContext.Provider
			value={{
				billingCycle,
				setBillingCycle,
				conciergePlan,
				setConciergePlan,
				conciergePaid,
				submitPayment,
				paymentConfirmed,
				paymentErrored,
				setPaymentErrored,
				paymentSubmitting,
				cancelSubscription,
				cancellationConfirmed,
				cancellationErrored,
				cancelling,
				downgradeAccountTier,
				downgradingConfirmed,
				downgradingErrored,
				downgrading,
			}}>
			{children}
		</ConciergeContext.Provider>
	);
};

export const ConciergeOptionsCards = ({
	onOptionSelect,
	handleSkip,
}: {
	onOptionSelect: (conciergeOption: string) => void;
	handleSkip: () => void;
}) => {
	const { details } = usePropertyContext();
	const { billingCycle, setBillingCycle } = useConciergeContext();
	return (
		<>
			<ConciergeOptionTab
				billingCycle={billingCycle}
				updateBillingCycle={setBillingCycle}
			/>
			<div className="concierge-options-cards">
				<NoConciergeOptionCard
					contingencyPercentage={
						details?.contingency ? details?.contingency : 25
					}
					onOptionSelect={handleSkip}
				/>
				<ConciergeOptionCard
					billingCycle={billingCycle}
					contingencyPercentage={
						details?.contingency ? details?.contingency : 25
					}
					onOptionSelect={onOptionSelect}
				/>
				<ConciergeProOptionCard
					billingCycle={billingCycle}
					contingencyPercentage={
						details?.contingency ? details?.contingency : 25
					}
					onOptionSelect={onOptionSelect}
				/>
			</div>
		</>
	);
};

const ConciergePage = () => {
	const { userData, setUserData } = useAuthContext();
	const { details } = usePropertyContext();

	const {
		conciergePlan,
		setConciergePlan,
		billingCycle,
		setBillingCycle,
		conciergePaid,
		cancelling,
		cancellationConfirmed,
		cancellationErrored,
		cancelSubscription,
	} = useConciergeContext();

	const [cancelModalOpen, setCancelModalOpen] = useState(false);

	const router = useRouter();

	const trackEvent = useEventTracker();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.Concierge_upgrade_page_viewed,
			data: {
				CustomerId: userData?.id,
			},
		});
		const internalScrollingComp = document.getElementById(
			"property-layout-content"
		);
		if (internalScrollingComp) {
			internalScrollingComp.scrollTo(0, 0);
		}
	}, []);

	const handleSkip = () => {
		router.push(`/account/properties/${details?.id}`);
	};

	const showOverviewPage = () => {
		router.push(`/account/properties/${details?.id}`);
	};

	const handlePaymentSuccess = async () => {
		const userData = await client.getUserData();
		setUserData(userData);
		trackEvent({
			eventName: constants.EVENTS.Concierge_upgrade_payment_action,
			data: {
				CustomerId: userData?.id,
				action: "submit",
			},
		});
	};

	const openCancellationModal = () => {
		setCancelModalOpen(true);
		trackEvent({
			eventName: constants.EVENTS.Concierge_cancellation_after_payment_viewed,
			data: {
				CustomerId: userData?.id,
			},
		});
	};

	const getConciergePageContent = () => {
		if (conciergePaid) {
			return (
				<>
					<ConciergeConfirmationContent />
					<div className="btn-container">
						<ButtonV2 size="small" variant="primary" onClick={showOverviewPage}>
							Go To Overview
						</ButtonV2>
						<p
							className="cancel-subscription sm"
							onClick={openCancellationModal}>
							cancel concierge service
						</p>
					</div>
				</>
			);
		} else if (
			(!!conciergePlan && !!billingCycle) ||
			userData?.account_tier?.includes("concierge")
		) {
			return (
				<>
					<p
						className="back-btn"
						onClick={() => {
							setConciergePlan("");
						}}>
						<BackIcon /> <span>Back</span>
					</p>
					<div className="concierge-page-payment-wrapper">
						<ConciergePayment handleContinueClick={handlePaymentSuccess} />
					</div>
				</>
			);
		} else {
			return (
				<>
					<p className="back-btn" onClick={showOverviewPage}>
						<BackIcon /> <span>Back</span>
					</p>
					<ConciergeOptionsCards
						handleSkip={handleSkip}
						onOptionSelect={plan => setConciergePlan(plan)}
					/>
				</>
			);
		}
	};

	const dontCancelSubsciption = () => {
		setCancelModalOpen(false);
		trackEvent({
			eventName: constants.EVENTS.Concierge_cancellation_after_payment_action,
			data: {
				CustomerId: userData?.id,
				action: "No Keep",
			},
		});
	};

	return (
		<div className="concierge-page-wrapper">
			{getConciergePageContent()}
			{cancelModalOpen && (
				<PropertyModal onAttemptExit={() => setCancelModalOpen(false)}>
					<div className="cancel-subsciption-modal-wrapper">
						<div className="confirmation-modal-wrapper">
							{cancellationConfirmed ? (
								<>
									<div className="warning-img-wrapper">
										<CircleCheck />
									</div>
									<p className="xl bold">Membership Canceled</p>
									<ButtonV2
										size="small"
										variant="primary"
										className="close-btn"
										onClick={showOverviewPage}>
										Close
									</ButtonV2>
								</>
							) : (
								<>
									<div className="warning-img-wrapper">
										<Warning />
									</div>
									<p className="xl bold">Cancel Concierge Services?</p>
									<p className="sm text-center">
										You will lose access to Concierge features, services and
										support. If we've achieved successful savings already from a
										service with a contingecy fee such as appeals, exemption
										refunds or bill reductions, you will still be expected to
										pay those invoices.
									</p>
									{cancellationErrored && (
										<p className="rust">
											There was an error cancelling your subscription. Please
											try again.
										</p>
									)}
									<div className="btn-container">
										<ButtonV2
											size="small"
											variant="destructive-outline"
											onClick={cancelSubscription}
											disabled={cancelling}>
											{cancelling ? "Cancelling..." : "Yes, Cancel Concierge"}
										</ButtonV2>
										<ButtonV2
											size="small"
											variant="primary-outline"
											onClick={dontCancelSubsciption}>
											No, Keep Finding Savings
										</ButtonV2>
									</div>
								</>
							)}
						</div>
					</div>
				</PropertyModal>
			)}
		</div>
	);
};

export default function Page() {
	return (
		<ConciergeContextProvider>
			<ConciergePage />
		</ConciergeContextProvider>
	);
}
