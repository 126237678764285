import { Button } from "components";
import {
	Dispatch,
	ReactElement,
	RefObject,
	SetStateAction,
	useEffect,
} from "react";
import { useRouter, useSearchParams } from "next/navigation";
import InsuranceIcon from "public/account/survey/serviceCards/insurance.svg";
import { usePropertyContext } from "../context";
import Image from "next/image";
import { sendErrorToWebhook } from "utils/sendWebhook";
import { formatRelativeUrl } from "utils/formatRelativeUrl";

export const InsuranceSurveyActivated = ({
	activatedRef,
	stage,
}: {
	activatedRef: RefObject<HTMLDivElement>;
	stage: string;
}) => {
	const { details, setModalToShow, fetchPropertyDetails } =
		usePropertyContext();

	const router = useRouter();
	const searchParams = useSearchParams();

	const onAttemptExit = () => {
		if (!details) return;
		setModalToShow(undefined);
		fetchPropertyDetails(details.id.toString(), {
			autoOpenNARModal: false,
		})
			.then(() => {
				router.push(
					formatRelativeUrl({
						pathname: `/account/properties/${details.id}`,
						query: {
							...(searchParams.get("onboarding") ? { onboarding: true } : {}),
						},
					})
				);
			})
			.catch(err => {
				console.error(err);
				sendErrorToWebhook(
					"Error caught in onAttemptExit of Exemptions Survey at the We Got it from here Page: " +
						err
				);
			});
	};

	const handleFinish = () => {
		onAttemptExit();
	};

	return (
		<div
			id={
				"insurance-survey-activated" + (stage === "activated" ? "-active" : "")
			}
			ref={activatedRef}>
			<div className="insurance-survey-activated-container">
				<div className="insurance-survey-activated-icon-container">
					<InsuranceIcon />
				</div>
				<div className="insurance-survey-activated-container-text-container">
					<p className="lg bold">Insurance Monitoring Activated!</p>
					<p className="sm">
						We’ll be in touch when we find you insurance savings
					</p>
				</div>
			</div>
			<Button
				id="insurance-survey-activated-btn"
				onClick={() => handleFinish()}>
				Finish
			</Button>
		</div>
	);
};
