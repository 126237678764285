"use client";
import { FC } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Script from "next/script";
import AnnouncementBanner from "elements/AnnouncementBanner";
import { usePathname } from "next/navigation";
import RealtorReferralPartnerBanner from "components/pages/Landing/RealtorReferralPartnerBanner";
import { PartnersBanner } from "components/pages/Landing/PartnersBanner";

const Layout: FC<{ banners?: React.ReactNode }> = ({ children, banners }) => {
	const pathname = usePathname();

	const showAnnouncementBanner = false;

	const hiddenChatBotPaths = ["/lp/welcome"];

	return (
		<>
			{/* <style jsx global>
				{`
					body {
						background-color: #edf6ff;
					}
				`}
			</style> */}
			{!hiddenChatBotPaths.includes(pathname) && (
				<Script
					id="ze-snippet"
					strategy="lazyOnload"
					src="https://static.zdassets.com/ekr/snippet.js?key=a6eaeaac-9943-44de-8edd-efde086ea77d"
					onLoad={() => {
						if (typeof window === "undefined") return;
						//@ts-ignore
						window.zESettings = {
							webWidget: {
								helpCenter: {
									originalArticleButton: false,
								},
							},
						};
					}}
				/>
			)}
			<div
				id="site-layout"
				className={[showAnnouncementBanner ? "fancy-w-banner" : "fancy"].join(
					" "
				)}>
				<Header>{showAnnouncementBanner && <AnnouncementBanner />}</Header>
				<div id="site-banners">{banners}</div>
				<main id="site-body">{children}</main>
				<Footer />
			</div>
		</>
	);
};

export default Layout;
