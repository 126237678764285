"use client";
import LandingLayout from "layout";
import ErrorPage from "components/pages/Error/ErrorPage";

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	return (
		<LandingLayout>
			<style global>
				{`
                    body {
                        background-color: white;
                    }
                `}
			</style>
			<ErrorPage errorType="500" />
		</LandingLayout>
	);
}
