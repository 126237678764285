import { ParsedUrlQueryInput } from "querystring";

export const formatRelativeUrl = ({
	pathname,
	query,
}: {
	pathname: string;
	query?: ParsedUrlQueryInput;
}) => {
	const cleanedQuery = Object.entries(query || {}).reduce(
		(acc, [key, value]) => {
			if (value === undefined || value === null) {
				return acc;
			}
			return [...acc, [key, value.toString()]];
		},
		[] as string[][]
	);

	const searchParams = new URLSearchParams(cleanedQuery);
	searchParams.toString();
	return `${pathname}${searchParams ? `?${searchParams}` : ""}`;
};
