import Button from "elements/Button";
import ButtonV2 from "elements/ButtonV2";
import ReviewStarOutline from "public/review-star-filled.svg";
import constants from "utils/constants";

export const StatSection = () => {
	return (
		<div id="landing-stat-section">
			<p className="landing-section-title">
				Only pay if you <span className="kelly-gradient">save</span>
			</p>
			<div id="landing-stat-section-stats">
				<div className="landing-stat-section-stat">
					<p className="landing-stat-section-stat-value azure-gradient">86%</p>
					<p className="landing-stat-section-stat-description">
						Receive a Reduction
					</p>
				</div>
				<div className="landing-stat-section-stat">
					<p className="landing-stat-section-stat-value azure-gradient">
						$1,148
					</p>
					<p className="landing-stat-section-stat-description">
						Average Savings
					</p>
				</div>
				<div className="landing-stat-section-stat">
					<p className="landing-stat-section-stat-value azure-gradient">
						4.8 <ReviewStarOutline className="review-star" />
					</p>
					<p className="landing-stat-section-stat-description">
						{constants.GOOGLE_REVIEW_COUNT}+ Google Reviews
					</p>
				</div>
			</div>
			<ButtonV2
				className="mx-auto landing-signup-today"
				size="large"
				toAppealFlow={{ referrer: "Stat section Sign Up Today" }}>
				Sign Up Today
			</ButtonV2>
		</div>
	);
};

export const GeneralizedStatSection = ({
	statInfo,
	toExemptionsFlow = false,
	titleNormal,
	titleHighlighted,
}: {
	statInfo: [
		{ statValue: string; description: string },
		{ statValue: string; description: string },
		{ statValue: string; description: string },
	];
	toExemptionsFlow?: boolean;
	titleNormal?: string;
	titleHighlighted?: string;
}) => {
	return (
		<div id="landing-stat-section">
			{titleNormal ? (
				<p className="landing-section-title">
					{titleNormal}{" "}
					{titleHighlighted ? (
						<span className="kelly-gradient">
							<br />
							{titleHighlighted}
						</span>
					) : null}
				</p>
			) : (
				<p className="landing-section-title">
					Only pay if you <span className="kelly-gradient">save</span>
				</p>
			)}

			<div id="landing-stat-section-stats">
				{statInfo.map(({ statValue, description }, key) => (
					<div
						className="landing-stat-section-stat"
						key={"only-pay-if-you-save-" + key}>
						<p className="landing-stat-section-stat-value azure-gradient">
							{statValue}
						</p>
						<p className="landing-stat-section-stat-description">
							{description}
						</p>
					</div>
				))}
			</div>
			<ButtonV2
				className="mx-auto landing-signup-today"
				size="large"
				toAppealFlow={{
					referrer: "Stat section Sign Up Today",
					exemptions: toExemptionsFlow ? true : false,
				}}>
				Sign Up Today
			</ButtonV2>
		</div>
	);
};
