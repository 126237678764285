import React, { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";

type ToggleOption = {
	label: string;
	value: string;
	href: string;
};

const MULTI_TOGGLE_OPTIONS: ToggleOption[] = [
	{
		label: "Residential",
		value: "residential",
		href: "/",
	},
	{
		label: "Commercial",
		value: "commercial",
		href: "/commercial",
	},
];

const MultiToggle = () => {
	const [selectedOption, setSelectedOption] = useState("residential");
	const [darkMode, setDarkMode] = useState(false);

	const router = useRouter();
	const pathname = usePathname();
	const trackEvent = useEventTracker();

	useEffect(() => {
		const currentPath = pathname;
		if (currentPath == "/commercial") {
			setSelectedOption("commercial");
			trackEvent({
				eventName: constants.EVENTS.Toggle_commercial,
			});
		} else {
			setSelectedOption("residential");
			trackEvent({
				eventName: constants.EVENTS.Toggle_residential,
			});
		}
	}, [pathname]);

	useEffect(() => {
		if (selectedOption != "residential") {
			setDarkMode(true);
		} else {
			setDarkMode(false);
		}
	}, [selectedOption]);

	const handleOptionChange = (option: ToggleOption) => {
		setSelectedOption(option.value);
		router.push(option.href);
	};

	return (
		<div id="multi-page-toggle" className={darkMode ? "dark-mode" : ""}>
			<p className="property-type-label sm">PROPERTY TYPE</p>
			<div className="toggle-btns">
				{MULTI_TOGGLE_OPTIONS.map(option => {
					return (
						<p
							key={option.value}
							className={
								"sm toggle-btn " +
								(option.value == selectedOption ? "active " : "")
							}
							onClick={() => handleOptionChange(option)}>
							{option.label}
						</p>
					);
				})}
			</div>
		</div>
	);
};

export default MultiToggle;
