import GlobeIcon from "public/globe.svg";
import { FC, useEffect, useState } from "react";
import { useRouter, usePathname } from "next/navigation";
import { switch_locale } from "app/_actions/switch-locale";

export const LanguagePickerDropdown: FC<
	React.HTMLAttributes<HTMLDivElement>
> = props => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const router = useRouter();
	const pathname = usePathname();

	const currentLocaleLabel = "en-US".split("-")[0].toUpperCase();

	const localOptions = ["en-US", "es-MX"]?.map(locale => {
		let label = locale.split("-")[0].toUpperCase();
		let icon = "";

		switch (label) {
			case "EN":
				icon = "🇺🇸 ";
				label = "English";
				break;
			case "ES":
				icon = "🇲🇽";
				label = "Español";
				break;
		}

		return {
			label,
			icon,
			value: locale,
		};
	});

	useEffect(() => {
		const closeDropdown = () => {
			setDropdownOpen(false);
		};

		if (dropdownOpen) {
			window.addEventListener("click", closeDropdown);
		} else {
			window.removeEventListener("click", closeDropdown);
		}

		return () => {
			window.removeEventListener("click", closeDropdown);
		};
	}, [dropdownOpen]);

	return (
		<div
			{...props}
			className={
				"language-picker-dropdown-container " + (props.className ?? "")
			}>
			<div
				className="language-picker-dropdown"
				onClick={e => {
					e.stopPropagation();
					setDropdownOpen(prevOpen => !prevOpen);
				}}>
				<GlobeIcon />
				<p className="sm language-picker-dropdown-locale">
					{currentLocaleLabel}
				</p>
			</div>
			{dropdownOpen && (
				<div className="language-picker-dropdown-list">
					{localOptions?.map(option => {
						return (
							<div
								key={option.value}
								className="language-picker-dropdown-list-item"
								onClick={() => {
									switch_locale({
										locale: option.value,
									});
								}}>
								<p className="sm no-translate">
									<span className="language-picker-dropdown-list-item-icon">
										{option.icon}
									</span>{" "}
									{option.label}
								</p>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
