import { useRouter, usePathname, useSearchParams } from "next/navigation";
import {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { sendErrorToWebhook } from "utils/sendWebhook";

export interface IReferralContext {
	referralCode: string | undefined;
	setReferralCode: Dispatch<SetStateAction<string | undefined>>;
}

export const ReferralContext = createContext<IReferralContext>({
	referralCode: undefined,
	setReferralCode: () => {},
});

export const ReferralContextProvider: FC = ({ children }) => {
	const [referralCode, setReferralCode] = useState<string>();

	const searchParams = useSearchParams();
	const pathname = usePathname();

	const setReferralCodePersistant = useCallback(
		(newReferralCode: string) => {
			try {
				window.sessionStorage.setItem("owl", newReferralCode);
				setReferralCode(newReferralCode);
			} catch (err) {
				console.error(err);
			}
		},
		[setReferralCode]
	);

	useEffect(() => {
		if (typeof window === "undefined") return;
		try {
			const existingReferralCode = window.sessionStorage.getItem("owl");

			if (existingReferralCode) {
				setReferralCodePersistant(existingReferralCode);
			}

			const handler = () => {
				const url = new URL(window.location.href);

				const urlReferralCode = url.searchParams.get("owl");

				if (urlReferralCode && urlReferralCode !== referralCode) {
					setReferralCodePersistant(urlReferralCode);
				}
			};

			handler();

			return () => {
				handler();
			};
		} catch (err) {
			console.error(err);
		}
	}, [searchParams, pathname]);

	return (
		<ReferralContext.Provider value={{ referralCode, setReferralCode }}>
			{children}
		</ReferralContext.Provider>
	);
};

export const useReferralContext = () => useContext(ReferralContext);
