import { useRouter } from "next/navigation";
import moment from "moment-timezone";

const AnnouncementBanner = ({
	disableRouting = false,
}: {
	disableRouting?: boolean;
}) => {
	const router = useRouter();
	const tempMessage =
		"We are still accepting signups in Harris and Tarrant County!";
	const tempMessageExpires = moment.tz("2024-05-20 17:00", "America/Chicago");

	const handleBannerOnClick = () => {
		if (disableRouting) return;

		if (moment.tz().isBefore(tempMessageExpires)) {
			router.push("/appeal");
		} else {
			router.push("/exemptions");
		}
	};

	const message = () => {
		if (moment.tz().isBefore(tempMessageExpires)) {
			return <p className="sm">{tempMessage}</p>;
		}

		return (
			<p className="sm">
				Looking for property tax exemptions?{" "}
				<span className="underline">Learn&nbsp;More</span>
			</p>
		);
	};

	return (
		<div
			id="landing-announcement-banner"
			onClick={() => handleBannerOnClick()}
			style={{ cursor: disableRouting ? "auto" : "pointer" }}>
			<div id="landing-announcement-container">{message()}</div>
		</div>
	);
};

export default AnnouncementBanner;
