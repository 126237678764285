import Label from "elements/Input/Label";
import ProgressBar from "elements/ProgressBar";
import Head from "next/head";
import {
	Dispatch,
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from "react";
import { hotjar } from "react-hotjar";
import {
	InsuranceAnswerReducerState,
	InsuranceQuestionID,
	PropertyDetails,
	PropertyInsurance,
} from "utils/types";
import { useQuestionState } from "utils/useQuestionState";
import { usePropertyContext } from "../context";
import PropertyModal from "../Modal";
import { InsuranceIntro } from "./InsuranceIntro";
import { InsuranceSurveyConfirmation } from "./InsuranceSurveyConfirmation";
// import { InsuranceSurveyLoading } from "./InsuranceSurveyLoading";
import BackArrow from "public/appeal-back-arrow.svg";
import { InsruanceSurveyQuestions } from "./InsuranceSurveyQuestions";
import { insuranceQuestions } from "./questions/questions";
import { useRouter, useSearchParams } from "next/navigation";
import {
	useEventTracker,
	useEventTracker_DEPRECATED,
} from "utils/useEventTracker";
import constants from "utils/constants";
import { useAppContext } from "context/AppContext";
import client from "utils/client";
import { InsuranceSurveyActivated } from "./InsuranceSurveyActivated";
import { useAuthContext } from "context/AuthContext";
import { useResponsiveContext } from "context/ResponsiveContext";
import { formatRelativeUrl } from "utils/formatRelativeUrl";
const TOTAL_ANIMATION_TIME = 250;
const HALF_ANIMATION_TIME = TOTAL_ANIMATION_TIME / 2;

export type AnswerReducerAction<T extends InsuranceQuestionID> = {
	id: T;
	payload: InsuranceAnswerReducerState[T];
};

export type AnswerReducerResetAction = {
	RESET: boolean;
	state: InsuranceAnswerReducerState;
};

export type DispatchAnswerState = Dispatch<
	AnswerReducerAction<InsuranceQuestionID> | AnswerReducerResetAction
>;
function answerReducer<T extends InsuranceQuestionID>(
	state: InsuranceAnswerReducerState,
	action: AnswerReducerAction<T> | AnswerReducerResetAction
) {
	if (action.hasOwnProperty("RESET")) {
		action = action as AnswerReducerResetAction;
		return {
			...action.state,
		};
	} else {
		action = action as AnswerReducerAction<T>;
		return {
			...state,
			[action.id]: action.payload,
		};
	}
}

const getDefaultValueByKey = (key: InsuranceQuestionID): string => {
	switch (key) {
		case "birthday":
		// case "credit_range":
		case "marital_status":
		case "current_premium":
		case "current_provider":
		case "current_deductible":
			return "";
	}
};

const initialAnswerState =
	insuranceQuestions.reduce<InsuranceAnswerReducerState>(
		(prev, cur) => ({
			...prev,
			[cur.id]: getDefaultValueByKey(cur.id),
		}),
		{} as InsuranceAnswerReducerState
	);

const getInitialAnswerState = (details: PropertyDetails) => {
	if (!details.insurance?.insurance_id) return initialAnswerState;

	return insuranceQuestions.reduce<InsuranceAnswerReducerState>((prev, cur) => {
		return {
			...prev,
			[cur.id]: details.insurance![cur.id] ?? getDefaultValueByKey(cur.id),
		};
	}, {} as InsuranceAnswerReducerState);
};

export const ALL_INSURANCE_STAGES = [
	"initial-questions",
	"confirmation",
	"loading",
	"quotes",
	"advisor",
	"activated",
] as const;

export type InsuranceSurveyStage = (typeof ALL_INSURANCE_STAGES)[number];

export const InsuranceSurvey = () => {
	const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(false);
	const [showPrevArrow, setShowPrevArrow] = useState(false);
	const [containerHeight, setContainerHeight] = useState(0);
	const [quotes, setQuotes] = useState<PropertyInsurance["quotes"] | null>(
		null
	);
	const [selectedQuote, setSelectedQuote] = useState<any | null>(null);
	const [stage, setStage] = useState<InsuranceSurveyStage>("initial-questions");
	const [errorInQuotesOrLeadGen, setErrorInQuotesOrLeadGen] = useState(false);

	const questionContainerRef = useRef<HTMLDivElement>(null);
	const initialQuestionsRef = useRef<HTMLDivElement>(null);
	const talkToAdvisorRef = useRef<HTMLDivElement>(null);
	const confirmationRef = useRef<HTMLDivElement>(null);
	const loadingRef = useRef<HTMLDivElement>(null);
	const resultsRef = useRef<HTMLDivElement>(null);
	const introRef = useRef<HTMLDivElement>(null);
	const activatedRef = useRef<HTMLDivElement>(null);

	const {
		details,
		satelliteUrlCss,
		setModalToShow,
		setLoading: setPropertyLoading,
		setDetails,
	} = usePropertyContext();
	const { hotjarInitialized } = useAppContext();
	const { userData } = useAuthContext();
	const trackEvent = useEventTracker();

	const [answerState, dispatchAnswerState] = useReducer(
		answerReducer,
		getInitialAnswerState(details!)
	);

	// If insurance id is already defined, then skip intro
	useEffect(() => {
		if (details?.insurance?.insurance_id) {
			setStage("initial-questions");
		}
	}, [details]);

	const questionState = useQuestionState({
		questions: insuranceQuestions,
		forceInitialProgress: 0,
		finalQuestionWeight: -1,
	});

	const {
		progress,
		next: originalNext,
		prev: originalPrev,
		activeQuestionId,
		resetProgress,
	} = questionState;

	const trackExitInsuranceSurvey = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_SURVEY_CLOSED,
		{
			Stage:
				stage === "initial-questions"
					? "Survey Questions: " + activeQuestionId
					: stage,
		}
	);

	const currentQuestionIndex = useMemo(
		() =>
			Math.max(
				insuranceQuestions.findIndex(({ id }) => id === activeQuestionId),
				0
			),
		[activeQuestionId]
	);

	useEffect(() => {
		// Hide and show the Prev arrow on the first and second slide
		if (currentQuestionIndex === 1) {
			setTimeout(() => setShowPrevArrow(true), HALF_ANIMATION_TIME);
		} else if (currentQuestionIndex === 0) {
			setTimeout(() => setShowPrevArrow(false), HALF_ANIMATION_TIME);
		}
	}, [currentQuestionIndex]);

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (nextBtnDisabled || prevBtnDisabled) {
			const handler = () => {
				setNextBtnDisabled(false);

				if (currentQuestionIndex > 0) {
					setPrevBtnDisabled(false);
				}
			};
			timeout = setTimeout(handler, TOTAL_ANIMATION_TIME);
		}
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	}, [nextBtnDisabled, prevBtnDisabled, currentQuestionIndex]);

	const next = useCallback(() => {
		if (nextBtnDisabled) return;
		setNextBtnDisabled(true);
		setPrevBtnDisabled(true);
		originalNext();
	}, [originalNext, nextBtnDisabled]);

	const prev = useCallback(() => {
		if (prevBtnDisabled) return;
		setPrevBtnDisabled(true);
		setNextBtnDisabled(true);
		originalPrev();
	}, [originalPrev, prevBtnDisabled]);

	const currentRef = useMemo<RefObject<HTMLDivElement>>(() => {
		switch (stage) {
			// case "intro":
			// 	return introRef;
			case "initial-questions":
				return initialQuestionsRef;
			case "confirmation":
				return confirmationRef;
			case "loading":
				return loadingRef;
			case "quotes":
				return resultsRef;
			case "advisor":
				return talkToAdvisorRef;
			case "activated":
				return activatedRef;
		}
	}, [stage]);

	useEffect(() => {
		if (!currentRef?.current) return;

		const handler = () => {
			if (currentRef.current && currentRef.current.offsetHeight) {
				setContainerHeight(currentRef.current.offsetHeight + 50);
			}
		};

		handler();

		window.addEventListener("resize", handler);

		const observer = new ResizeObserver(() => {
			handler();
		});

		observer.observe(currentRef.current);

		return () => {
			window.removeEventListener("resize", handler);
			observer.disconnect();
		};
	}, [currentRef, activeQuestionId]);

	const onResetSurvey = () => {
		questionState.reset();
		setQuotes(null);
		setStage("initial-questions");
	};

	const onBackButtonClick = () => {
		if (stage === "confirmation") {
			resetProgress();
			onResetSurvey();
		} else {
			setStage("quotes");
		}
	};

	const showProgressBar = useMemo(() => {
		return !["quotes", "loading", "advisor"].includes(stage);
	}, [stage]);

	const showBackArrow = useMemo(() => {
		return (
			stage === "confirmation" || (stage === "advisor" && !!quotes?.length)
		);
	}, [stage, quotes]);

	const hotjarTrackInsurance = () => {
		hotjar.event(constants.HOTJAR.started_insurance_flow);
	};

	useEffect(() => {
		if (hotjarInitialized) {
			hotjarTrackInsurance();
		}
	}, [hotjarInitialized]);

	const router = useRouter();
	const searchParams = useSearchParams();

	const onModalExit = async () => {
		try {
			if (!details) return;
			setModalToShow(undefined);
			trackExitInsuranceSurvey();

			if (!!searchParams.get("onboarding")) {
				trackEvent({
					eventName: constants.EVENTS.Onboarding_insurance_exit,
					data: {
						"Customer ID": userData?.id,
						"Property ID": details?.id,
					},
				});
			}

			router.push(
				formatRelativeUrl({
					pathname: `/account/properties/${details?.id}`,
					query: {
						...(searchParams.get("onboarding") ? { onboarding: true } : {}),
					},
				})
			);

			const res = await client.getProperty({ id: details.id.toString() });

			setDetails(res.data);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<PropertyModal modalId="insurance-survey-modal" onAttemptExit={onModalExit}>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			{showBackArrow && (
				<div id="insurance-survey-modal-back-arrow" onClick={onBackButtonClick}>
					<BackArrow />
					<p>Back</p>
				</div>
			)}
			{showProgressBar && (
				<div id="insurance-survey-progress-bar-container">
					<p className="sm no-translate">
						{[
							details?.address +
								(details?.address2 ? " " + details.address2 : ""),
							details?.city,
							details?.state +
								(details?.zip_code ? " " + details.zip_code : ""),
						].join(", ")}
					</p>
					<ProgressBar
						labelBelow
						complete={stage === "activated" ? 100 : progress}
						color={Math.round(progress) === 100 ? "mint2" : undefined}
						id="insurance-survey-progress-bar"
					/>
				</div>
			)}
			<div id="insurance-survey" style={{ height: containerHeight }}>
				{/* <InsuranceIntro stage={stage} setStage={setStage} introRef={introRef} />*/}
				<InsruanceSurveyQuestions
					prev={prev}
					next={next}
					stage={stage}
					setStage={setStage}
					activeQuestionId={activeQuestionId}
					currentQuestionIndex={currentQuestionIndex}
					initialQuestionsRef={initialQuestionsRef}
					questionContainerRef={questionContainerRef}
					nextBtnDisabled={nextBtnDisabled}
					showPrevArrow={showPrevArrow}
					answerState={answerState}
					dispatchAnswerState={dispatchAnswerState}
					prevBtnDisabled={prevBtnDisabled}
				/>
				<InsuranceSurveyConfirmation
					stage={stage}
					error={errorInQuotesOrLeadGen}
					setError={setErrorInQuotesOrLeadGen}
					setStage={setStage}
					answerState={answerState}
					confirmationRef={confirmationRef}
					onResetSurvey={onResetSurvey}
				/>
				<InsuranceSurveyActivated stage={stage} activatedRef={activatedRef} />
			</div>
		</PropertyModal>
	);
};

// --------------
// SCROLL SUPPORT
// --------------
// useEffect(() => {
// 	const questionContainer = questionContainerRef.current;
//
// 	if (!questionContainer) return;
//
// 	const handler = debounce(
// 		(e: WheelEvent) => (e.deltaY > 0 ? next() : prev()),
// 		250,
// 		true
// 	);
//
// 	questionContainer.addEventListener("wheel", handler);
//
// 	return () => {
// 		questionContainer?.removeEventListener("wheel", handler);
// 	};
// }, [next, prev]);

// -------------
// ARROW SUPPORT
// -------------
// useEffect(() => {
// 	const handler = (e: KeyboardEvent) => {
// 		switch (e.key) {
// 			case "ArrowUp":
// 				return prev();
// 			case "ArrowDown":
// 				return next();
// 		}
// 	};
//
// 	window.addEventListener("keydown", handler);
//
// 	return () => {
// 		window.removeEventListener("keydown", handler);
// 	};
// }, [next, prev]);
