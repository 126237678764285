import ExitAreYouSure from "components/pages/Appeal/ExitAreYouSure";
import { useAuthContext } from "context/AuthContext";
import { useSearchParams, useRouter } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import client from "utils/client";
import constants from "utils/constants";
import {
	useEventTracker,
	useEventTracker_DEPRECATED,
} from "utils/useEventTracker";
import { usePropertyContext } from "../../context";
import moment from "moment";
import BadgeClock from "public/account/badge-clock.svg";

import useDeadline from "utils/useDeadline";
import ButtonV2 from "elements/ButtonV2";

const Intro = () => {
	const { next, details, activeQuestionId, setModalToShow, profileFlowDone } =
		usePropertyContext();
	const trackBeginPropertySurvey = useEventTracker_DEPRECATED(
		constants.EVENTS.PORTAL_PROPERTY_SURVEY_BEGIN
	);

	const [showDeclineSurveyModal, setShowDeclineSurveyModal] = useState(false);

	const router = useRouter();
	const searchParams = useSearchParams();
	const trackEvent = useEventTracker();

	const { setLoading: setPropertyLoading, setDetails } = usePropertyContext();
	const { userData } = useAuthContext();

	const handleDeclineSurvey = async () => {
		try {
			if (!details) return;

			setPropertyLoading(true);

			await client.declinePropertySurvey({ appealId: details.appeal_id });

			router.push(`/account/properties/${details.id}`);

			const { data } = await client.getProperty({ id: details.id.toString() });

			setDetails(data);

			setPropertyLoading(false);
			setModalToShow(undefined);
		} catch (e) {
			console.error(e);
		}
	};

	const { deadline, showDeadlineBadge } = useDeadline();

	const handleSubmit = async () => {
		try {
			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {},
			});

			trackBeginPropertySurvey();
			if (!!searchParams.get("onboarding")) {
				trackEvent({
					eventName: constants.EVENTS.Onboarding_property_survey_begin_clicked,
					data: {
						"Customer ID": userData?.id,
						"Property ID": details?.id,
					},
				});
			}
			next();
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (!!searchParams.get("onboarding")) {
			trackEvent({
				eventName: constants.EVENTS.Onboarding_property_survey_page_viewed,
				data: {
					"Customer ID": userData?.id,
					"Property ID": details?.id,
				},
			});
		}
	}, [searchParams]);

	return (
		<div id="account-property-profile-intro">
			<div id="account-property-profile-intro-title-and-badge">
				<p className="azure">
					<b>Property Survey</b>
				</p>
				{/* {showDeadlineBadge && deadline && (
					<p className="account-property-profile-deadline-badge">
						Complete by <span className="no-translate">{deadline}</span>{" "}
						<BadgeClock />
					</p>
				)} */}
			</div>

			<p className="lg bold">
				{searchParams.get("onboarding")
					? "Let’s start with your property survey"
					: "The following questions will help us maximize your savings!"}
			</p>
			<p className="denim-medium">
				The more we know, the more you could save. Please take a few minutes to
				answer the following questions to see if you have any evidence that
				could help us maximize your savings.
			</p>
			<ButtonV2
				size="large"
				id="account-property-profile-intro-begin"
				onClick={handleSubmit}>
				Begin
			</ButtonV2>
			<div id="account-property-profile-intro-info-box">
				<p className="bold">Here is what works with the assessors:</p>
				<ul>
					<li className="sm denim-medium">
						Structural or other damages and maintenance over $5,000, with
						receipts such as bids, quotes, insurance claims
					</li>
					<li className="sm denim-medium">
						Photos or evidence with dates that provide context for the claims
					</li>
				</ul>
				<p className="sm denim-medium">
					Any claims without supporting documents, small maintenance (such as
					fence repair/landscaping) or damages under $5,000 are usually
					dismissed.
				</p>
				{showDeadlineBadge && deadline && (
					<p className="sm denim-medium">
						Please complete the survey by{" "}
						<span className="no-translate">{deadline}</span>.
					</p>
				)}
			</div>
			{showDeclineSurveyModal && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Are you sure you want to dismiss the survey? This will notify
								your local property tax consultant they can proceed without
								additional evidence from you.
							</p>
						</div>
					}
					exitBtnContent={<p>Dismiss Survey</p>}
					cancelBtnContent={<p>Back</p>}
					onExit={() => {
						setShowDeclineSurveyModal(false);
						handleDeclineSurvey();
					}}
					onCancel={() => {
						setShowDeclineSurveyModal(false);
					}}
				/>
			)}
		</div>
	);
};

export default Intro;
