import { useRouter, usePathname, useSearchParams } from "next/navigation";
import {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import marketingLinkRewrites from "config/marketing-links.json";
import partnerLinkRewrites from "config/partner-pages.json";
import realtorReferalPartners from "config/realtor-referral-partners.json";
import { sendErrorToWebhook } from "utils/sendWebhook";
import { useAppContext } from "context/AppContext";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { url } from "inspector";

export interface IOfferContext {
	offer: string | undefined;
	setOffer: Dispatch<SetStateAction<string | undefined>>;
	offerSetAt: string | undefined;
	setOfferSetAt: Dispatch<SetStateAction<string | undefined>>;
	partnerRef: string | undefined;
	showOfferModal: boolean;
	setShowOfferModal: Dispatch<SetStateAction<boolean>>;
	offerContents: { title: string; subtitle: string } | undefined;
	contingency: number | undefined;
	setContingency: Dispatch<SetStateAction<number | undefined>>;
	partialAttribution: boolean | undefined;
}

export const OfferContext = createContext<IOfferContext>({
	offer: undefined,
	setOffer: () => {},
	offerSetAt: undefined,
	setOfferSetAt: () => {},
	partnerRef: undefined,
	showOfferModal: false,
	setShowOfferModal: () => {},
	offerContents: undefined,
	contingency: undefined,
	setContingency: () => {},
	partialAttribution: undefined,
});

const extractOfferFromUrl = (): string | undefined => {
	if (typeof window === "undefined") return;

	const allMarketingUrls = marketingLinkRewrites.map(({ source }) => source);
	const allPartnerPageUrls = Object.values(partnerLinkRewrites).reduce<
		string[]
	>((acc, parterConfig) => [...acc, ...parterConfig.urls], []);
	const allRealtorPartners = Object.values(realtorReferalPartners).reduce<
		string[]
	>(
		(acc, realtorReferalPartnersConfig) => [
			...acc,
			...realtorReferalPartnersConfig.urls,
		],
		[]
	);

	const url = new URL(window.location.href);
	const params = url.searchParams;

	if (allMarketingUrls.includes(url.pathname)) return url.pathname.slice(1);

	if (allPartnerPageUrls.includes(url.pathname.slice(1))) {
		return url.pathname.slice(1);
	}

	if (allRealtorPartners.includes(url.pathname.replace(/^\/partner\//g, ""))) {
		return url.pathname.replace(/^\/partner\//g, "");
	}

	const allNewPartnerPageUrls = allPartnerPageUrls.map(
		url => "/partners/" + url
	);

	if (allNewPartnerPageUrls.includes(url.pathname)) {
		return url.pathname.replace("/partners/", "");
	}

	if (params.get("utm_campaign")) {
		return params.get("utm_campaign") || undefined;
	}

	if (params.get("partner_ref")) {
		return params.get("partner_ref") || undefined;
	}

	if (url.pathname.startsWith("/lp")) {
		return `lp_${url.pathname.slice(4)}`;
	}

	if (url.pathname.startsWith("/partners")) {
		return url.pathname.slice(10);
	}

	if (url.pathname.startsWith("/partner")) {
		return url.pathname.slice(9);
	}
};

const clearOfferFromLocalStorage = () => {
	try {
		window.localStorage.removeItem(OFFER_LOCAL_STORAGE_KEY);
		window.sessionStorage.removeItem(OFFER_PARTIAL_ATTRIBUTION_KEY);
	} catch (err) {
		console.error(err);
	}
};

const parseOfferFromLocalStorage = ():
	| { offer: string; set_at: string }
	| undefined => {
	try {
		const existingOffer = window.localStorage.getItem(OFFER_LOCAL_STORAGE_KEY);

		const existingOfferParsed = existingOffer
			? JSON.parse(existingOffer)
			: null;

		if (!existingOfferParsed) return;

		// Support backwards compatibility with `expires`
		if (existingOfferParsed.expires) {
			existingOfferParsed.set_at = moment(existingOfferParsed.expires).subtract(
				1,
				"day"
			);
			delete existingOfferParsed.expires;
			window.localStorage.setItem(
				OFFER_LOCAL_STORAGE_KEY,
				JSON.stringify(existingOfferParsed)
			);
		}

		return existingOfferParsed;
	} catch (err) {
		console.error(err);
	}
};

const OFFER_LOCAL_STORAGE_KEY = "offer";
const OFFER_PARTIAL_ATTRIBUTION_KEY = "offer_partial_attribution";

export const OfferContextProvider: FC = ({ children }) => {
	const [offer, setOffer] = useState<string>();
	const [offerSetAt, setOfferSetAt] = useState<string>();
	const [offerContents, setOfferContents] = useState<{
		title: string;
		subtitle: string;
	}>();
	const [showOfferModal, setShowOfferModal] = useState(false);
	const [contingency, setContingency] = useState<number>();
	const [partialAttribution, setPartialAttribution] = useState<boolean>();

	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();

	const { mixpanelInitialized } = useAppContext();

	const setOfferPersistant = useCallback((newOffer: string) => {
		try {
			const offerBody = {
				offer: newOffer,
				set_at: moment().toISOString(),
			};

			setPartialAttribution(false);

			window.sessionStorage.setItem(OFFER_PARTIAL_ATTRIBUTION_KEY, "false");

			window.localStorage.setItem(
				OFFER_LOCAL_STORAGE_KEY,
				JSON.stringify(offerBody)
			);

			setOffer(offerBody.offer);
			setOfferSetAt(offerBody.set_at);
		} catch (err) {
			console.error(err);
		}
	}, []);

	const partnerRef = useMemo(() => {
		if (typeof window === "undefined") return;

		const reduceUrlsFromConfig = (
			accumulator: string[],
			currentConfig: { urls: string[] }
		) => {
			return [...accumulator, ...currentConfig.urls];
		};
		const allUrls = [
			...Object.values(partnerLinkRewrites).reduce(reduceUrlsFromConfig, []),
			...Object.values(realtorReferalPartners).reduce(reduceUrlsFromConfig, []),
		];

		for (const url of allUrls) {
			if (offer === url) {
				return offer;
			}
		}
	}, [offer]);

	useEffect(() => {
		if (offer && mixpanelInitialized) {
			mixpanel.register({
				campaign: offer,
			});
		}
	}, [offer, mixpanelInitialized]);

	useEffect(() => {
		if (typeof window === "undefined") return;
		try {
			const existingOffer = parseOfferFromLocalStorage();

			if (existingOffer) {
				setOffer(existingOffer.offer);
				setOfferSetAt(existingOffer.set_at);

				// If the customer comes back to ownwell in a new session after we have set their offer apply partial attribution
				if (!sessionStorage.getItem(OFFER_PARTIAL_ATTRIBUTION_KEY)) {
					setPartialAttribution(true);

					window.sessionStorage.setItem(OFFER_PARTIAL_ATTRIBUTION_KEY, "true");
				}
			}

			const handler = () => {
				const urlOffer = extractOfferFromUrl();

				if (urlOffer) {
					setOfferPersistant(urlOffer);
				}

				if (!sessionStorage.getItem(OFFER_PARTIAL_ATTRIBUTION_KEY)) {
					setPartialAttribution(true);

					window.sessionStorage.setItem(OFFER_PARTIAL_ATTRIBUTION_KEY, "true");
				}
			};

			handler();
			return () => {
				handler();
			};
		} catch (err) {
			console.error(err);
		}
	}, [searchParams, pathname]);

	// useEffect(() => {
	// 	switch (offer) {
	// 		case "FebPost_Offer":
	// 			setOfferContents({
	// 				title: "Your discount has applied!",
	// 				subtitle:
	// 					"Remember to complete your sign up to receive the discount.",
	// 			});
	// 			break;
	// 		case "save":
	// 			setOfferContents({
	// 				title: "Your discount has applied!",
	// 				subtitle:
	// 					"Remember to complete your sign up by March 31 to receive your free Yeti tumbler. New customers only.",
	// 			});

	// 			break;
	// 		case "altgage":
	// 		case "specialized247":
	// 		case "vedulagroup":
	// 			setOfferContents({
	// 				title: "Your discount has applied!",
	// 				subtitle:
	// 					"Any properties that you sign up during this session will receive a discounted rate of 20% instead of our typical 25% contingency!",
	// 			});

	// 			break;
	// 	}
	// }, [offer]);

	// useEffect(() => {
	// 	if (offer && offerContents) {
	// 		setShowOfferModal(true);
	// 	}
	// }, [offer, offerContents]);

	return (
		<OfferContext.Provider
			value={{
				offer,
				setOffer,
				offerSetAt,
				setOfferSetAt,
				partnerRef,
				contingency,
				setContingency,
				showOfferModal,
				setShowOfferModal,
				offerContents,
				partialAttribution,
			}}>
			{children}
		</OfferContext.Provider>
	);
};

export const useOfferContext = () => useContext(OfferContext);
