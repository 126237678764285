import { FC, useEffect, useState } from "react";
import { useRouter, usePathname } from "next/navigation";
import { switch_locale } from "app/_actions/switch-locale";

export const LanguagePickerSwitch: FC<
	React.HTMLAttributes<HTMLDivElement>
> = props => {
	const router = useRouter();
	const pathname = usePathname();

	const localOptions = ["en-US", "es-MX"]?.map(locale => {
		let label = locale.split("-")[0].toUpperCase();
		let icon = "";

		switch (label) {
			case "EN":
				icon = "🇺🇸 ";
				label = "English";
				break;
			case "ES":
				icon = "🇲🇽";
				label = "Español";
				break;
		}

		return {
			label,
			icon,
			value: locale,
		};
	});

	return (
		<div
			{...props}
			className={"language-picker-switch " + (props.className ?? "")}>
			{localOptions.map(option => (
				<div
					key={option.value}
					className={
						"language-picker-switch-item" +
						(document.cookie
							.split("; ")
							.find(cookie => cookie.startsWith("Next-Locale="))
							?.replace(/Next\-Locale\=/g, "") === option.value
							? "-active"
							: "")
					}
					onClick={() => {
						switch_locale({
							locale: option.value,
						});
					}}>
					<p className="sm nowrap">
						{option.icon} {option.label}
					</p>
				</div>
			))}
		</div>
	);
};
